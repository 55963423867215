import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Switch,
	FormControlLabel
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import InputMask from "react-input-mask";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto! Mínimo de 2 caracteres.")
		.max(50, "Muito longo! Máximo de 50 caracteres.")
		.required("Campo obrigatório."),
	email: Yup.string()
		.email("Email inválido.")
		.required("Campo obrigatório."),
	phone: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	cpfCnpj: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	zipcode: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	street: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	number: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	neighborhood: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	city: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
	state: Yup.string()
		.nullable()
		.required("Campo obrigatório."),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
		allTicket: "desabled",
		phone: "",
		cpfCnpj: "",
		zipcode: "",
		personType: "Física", // Definindo o valor inicial
		street: "",
		number: "",
		neighborhood: "",
		city: "",
		state: "",
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [whatsappId, setWhatsappId] = useState(false);
	const { loading, whatsApps } = useWhatsApps();
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds, allTicket: values.allTicket };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	// Função para buscar endereço via ViaCEP
	const fetchAddress = async (cep, setFieldValue) => {
		const cleanedCep = cep.replace(/\D/g, '');
		if (cleanedCep.length !== 8) {
			toast.error("CEP inválido. Deve conter 8 dígitos.");
			return;
		}
		try {
			const response = await fetch(`https://viacep.com.br/ws/${cleanedCep}/json/`);
			const data = await response.json();
			if (data.erro) {
				toast.error("CEP não encontrado.");
				return;
			}
			setFieldValue('street', data.logradouro || '');
			setFieldValue('neighborhood', data.bairro || '');
			setFieldValue('city', data.localidade || '');
			setFieldValue('state', data.uf || '');
		} catch (error) {
			toast.error("Erro ao buscar CEP.");
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, handleChange, values, isSubmitting, setFieldValue }) => (

						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										fullWidth
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((prev) => !prev)}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required
													>
														<MenuItem value="admin">Admin</MenuItem>
														<MenuItem value="user">User</MenuItem>
													</Field>
												</>
											)}
										/>
									</FormControl>
								</div>

								<div className={classes.multFieldLine}>
									<InputMask
										mask="(99) 99999-9999"
										value={values.phone}
										onChange={handleChange}
									>
										{() => (
											<TextField
												label={i18n.t("userModal.form.phone")}
												name="phone"
												error={touched.phone && Boolean(errors.phone)}
												helperText={touched.phone && errors.phone}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										)}
									</InputMask>
								</div>

								<div className={classes.multFieldLine}>
									<FormControl variant="outlined" fullWidth margin="dense">
										<InputLabel>{i18n.t("userModal.form.personType")}</InputLabel>
										<Select
											label={i18n.t("userModal.form.personType")}
											name="personType"
											value={values.personType}
											onChange={handleChange}
											fullWidth
										>
											<MenuItem value="Física">Física</MenuItem>
											<MenuItem value="Jurídica">Jurídica</MenuItem>
										</Select>
									</FormControl>

									<InputMask
										mask={values.personType === "Física" ? "999.999.999-99" : "99.999.999/9999-99"}
										value={values.cpfCnpj}
										onChange={handleChange}
									>
										{() => (
											<TextField
												label={i18n.t("userModal.form.cpfCnpj")}
												name="cpfCnpj"
												error={touched.cpfCnpj && Boolean(errors.cpfCnpj)}
												helperText={touched.cpfCnpj && errors.cpfCnpj}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										)}
									</InputMask>
								</div>

								<div className={classes.multFieldLine}>
									<InputMask
										mask="99999-999"
										value={values.zipcode}
										onChange={handleChange}
										onBlur={async (e) => {
											handleChange(e); // Atualiza o valor do campo no Formik
											const cep = e.target.value;
											if (cep.replace(/\D/g, '').length === 8) { // Verifica se o CEP tem 8 dígitos
												await fetchAddress(cep, setFieldValue);
											}
										}}
									>
										{() => (
											<TextField
												label={i18n.t("userModal.form.zipcode")}
												name="zipcode"
												error={touched.zipcode && Boolean(errors.zipcode)}
												helperText={touched.zipcode && errors.zipcode}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										)}
									</InputMask>
								</div>

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.street")}
										name="street"
										error={touched.street && Boolean(errors.street)}
										helperText={touched.street && errors.street}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.number")}
										name="number"
										error={touched.number && Boolean(errors.number)}
										helperText={touched.number && errors.number}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.neighborhood")}
										name="neighborhood"
										error={touched.neighborhood && Boolean(errors.neighborhood)}
										helperText={touched.neighborhood && errors.neighborhood}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.city")}
										name="city"
										error={touched.city && Boolean(errors.city)}
										helperText={touched.city && errors.city}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.state")}
										name="state"
										error={touched.state && Boolean(errors.state)}
										helperText={touched.state && errors.state}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>

								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editProfile"
									yes={() => (
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>
												{i18n.t("userModal.form.whatsapp")}
											</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}

											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>

								<Can
									role={loggedInUser.profile}
									perform="user-modal:editProfile"
									yes={() => (!loading &&
										<div className={classes.textField}>
											<FormControl
												variant="outlined"
												className={classes.maxWidth}
												margin="dense"
												fullWidth
											>
												<>
													<InputLabel id="allTicket-selection-input-label">
														{i18n.t("userModal.form.allTicket")}
													</InputLabel>

													<Field
														as={Select}
														label={i18n.t("userModal.form.allTicket")}
														name="allTicket"
														labelId="allTicket-selection-label"
														id="allTicket-selection"
														required
													>
														<MenuItem value="enabled">{i18n.t("userModal.form.allTicketEnabled")}</MenuItem>
														<MenuItem value="desabled">{i18n.t("userModal.form.allTicketDesabled")}</MenuItem>
													</Field>
												</>
											</FormControl>
										</div>

									)}
								/>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
