import React, { useState, useEffect } from "react";
import qs from 'query-string';
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import {
	Button, CssBaseline, TextField, Link, Grid, Box, Typography,
	Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, CardActions,
	Container, makeStyles,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Stepper,
	Step,
	StepLabel
} from "@material-ui/core";
import InputMask from 'react-input-mask';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { i18n } from "../../translate/i18n";
import api, { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import axios from 'axios';

const steps = ["Dados Pessoais", "Endereço", "Plano"];
const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`;
const randomValue = Math.random();
const logoWithRandom = `${logo}?r=${randomValue}`;
const logo2 = `/og.png`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100vw",
		height: "100vh",
		backgroundImage: `url(${logo2})`, 
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	imageSection: {
		flex: 2,
		backgroundColor: "#0ab671",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down('sm')]: {
			display: "none",
		},
	},
	image: {
		width: "60%",
		[theme.breakpoints.down('md')]: {
			width: "80%",
		},
	},
	formSection: {
		flex: 1.5,
		backgroundColor: "#ebfff1",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			backgroundColor: "#0ab671",
			width: "100vw",
			height: "100vh",
			padding: theme.spacing(1),
		},
	},
	formContainer: {
		width: "100%",
		maxWidth: "600px",
		backgroundColor: "#fff",
		borderRadius: "10px",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
		padding: theme.spacing(4),
		textAlign: "center",
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3),
			boxShadow: "none",
			borderRadius: "10px",
		},
	},
	title: {
		marginBottom: theme.spacing(4),
		color: "#2C2C2C",
		fontWeight: "bold",
	},
	card: {
		marginBottom: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: "#0ab671",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#02532c",
		},
	},
	textField: {
		marginBottom: theme.spacing(2),
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ddd",
			},
			"&:hover fieldset": {
				borderColor: "#0ab671",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#0ab671",
				borderWidth: "2px",
			},
			"& input": {
				color: "#555",
			},
		},
	},
	link: {
		color: "#0ab671",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	logo: {
		width: "80px",
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			width: "100px",
		},
	},
}));

const DadosPessoaisSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto!")
		.max(50, "Muito longo!")
		.required("Campo obrigatório"),
	password: Yup.string()
		.min(5, "Muito curta!")
		.max(50, "Muito longa!")
		.required("Campo obrigatório"),
	email: Yup.string().email("Email inválido").required("Campo obrigatório"),
	phone: Yup.string().required("Campo obrigatório"),
	cpfCnpj: Yup.string().required("Campo obrigatório"),
	personType: Yup.string().required("Campo obrigatório"),
});

const EnderecoSchema = Yup.object().shape({
	street: Yup.string().required("Campo obrigatório"),
	number: Yup.string().required("Campo obrigatório"),
	neighborhood: Yup.string().required("Campo obrigatório"),
	city: Yup.string().required("Campo obrigatório"),
	state: Yup.string().required("Campo obrigatório"),
	zipcode: Yup.string().required("Campo obrigatório"),
});

const PlanoSchema = Yup.object().shape({
	planId: Yup.string().required("Campo obrigatório"),
});

const validationSchemas = [DadosPessoaisSchema, EnderecoSchema, PlanoSchema];
const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto!")
		.max(50, "Muito longo!")
		.required("Campo obrigatório"),
	password: Yup.string()
		.min(5, "Muito curta!")
		.max(50, "Muito longa!")
		.required("Campo obrigatório"),
	email: Yup.string().email("Email inválido").required("Campo obrigatório"),
	phone: Yup.string().required("Campo obrigatório"),
	cpfCnpj: Yup.string().required("Campo obrigatório"),
	street: Yup.string().required("Campo obrigatório"),
	number: Yup.string().required("Campo obrigatório"),
	neighborhood: Yup.string().required("Campo obrigatório"),
	city: Yup.string().required("Campo obrigatório"),
	state: Yup.string().required("Campo obrigatório"),
	zipcode: Yup.string().required("Campo obrigatório"),
	personType: Yup.string().required("Campo obrigatório"),
});



const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();
	const [allowregister, setAllowRegister] = useState('enabled');
	const [trial, setTrial] = useState('7');
	const [plans, setPlans] = useState([]);
	const { register: listPlans } = usePlans();
	const [openPlanModal, setOpenPlanModal] = useState(false); // Controle do modal
	const [selectedPlan, setSelectedPlan] = useState({ id: "", name: "", price: "" });
	const [showPassword, setShowPassword] = useState(false); // Exibir/esconder senha
	const [currentStep, setCurrentStep] = useState(0);
	let companyId = null;

	useEffect(() => {
		fetchAllowRegister();
		fetchTrial();
	}, []);

	const fetchTrial = async () => {
		/*try {
			const response = await api.get("/settings/trial");
			const trialDays = response.data.value;
			setTrial(trialDays);
		} catch (error) {
			console.error('Error retrieving trial', error);
		}*/
	};

	const fetchAllowRegister = async () => {
		/*try {
			const response = await api.get("/settings/allowregister");
			const allowRegisterValue = response.data.value;
			setAllowRegister(allowRegisterValue);
		} catch (error) {
			console.error('Error retrieving allowregister', error);
		}*/
	};

	const fetchPlans = async () => {
		try {
			const list = await listPlans();
			console.log({ list });

			let planos = [];

			list.forEach(plan => {

				//5, 4, 3

				if ([5, 4, 3].includes(plan.id)) {
					planos.push(plan);
				}

			});


			setPlans(planos);
		} catch (error) {
			console.error('Error retrieving plans', error);
		}
	};

	useEffect(() => {
		fetchPlans();
	}, []);

	if (allowregister === "disabled") {
		history.push("/login");
	}

	const params = qs.parse(window.location.search);
	if (params.companyId !== undefined) {
		companyId = params.companyId;
	}

	const initialState = {
		name: "",
		email: "",
		phone: "",
		password: "",
		planId: "disabled",
		personType: "Física",
		cpfCnpj: "",
		street: "",
		number: "",
		neighborhood: "",
		city: "",
		state: "",
		zipcode: "",
	};

	const [user] = useState(initialState);
	const dueDate = moment().add(trial, "day").format();
	const [formValues, setFormValues] = useState(initialState);

	const handleSignUp = async (values) => {
		values.planId = selectedPlan.id; // Adiciona o planId selecionado

		// Montar o objeto customer para enviar ao backend
		const customer = {
			name: values.name,
			email: values.email,
			phone_number: values.phone,
			cpf: values.personType === "Física" ? values.cpfCnpj : undefined,
			cnpj: values.personType === "Jurídica" ? values.cpfCnpj : undefined,
			address: {
				street: values.street,
				number: values.number,
				neighborhood: values.neighborhood,
				city: values.city,
				state: values.state,
				zipcode: values.zipcode.replace(/\D/g, ''),
			},
			juridical_person: values.personType === "Jurídica" ? {
				corporate_name: values.name,
				cnpj: values.cpfCnpj.replace(/\D/g, ''),
			} : undefined,
		};

		// Montar os dados a serem enviados para o backend
		const data = {
			...values,
			customer, // Inclui o objeto customer completo
			recurrence: "MENSAL",
			dueDate,
			status: "t",
			campaignsEnabled: true,
		};

		try {
			await openApi.post("/companies/cadastro", data);
			toast.success(i18n.t("signup.toasts.success"));

			setTimeout(() => {
				history.push("/login");
			}, 3500);
		} catch (err) {
			console.log(err);
			toastError(err);
		}
	};


	const handleClickShowPassword = () => setShowPassword(!showPassword);

	// Abrir e fechar modal de planos
	const handleOpenPlanModal = () => {
		setOpenPlanModal(true);
	};

	const handleClosePlanModal = () => {
		setOpenPlanModal(false);
	};

	const handleSelectPlan = (plan) => {

		console.log({ plan });


		// Armazena o id, nome e preço do plano selecionado
		setSelectedPlan({ id: plan.id, name: plan.name, price: plan.value });
		// Atualiza o planId no estado do formulário
		user.planId = plan.id; // Atualiza o campo planId no objeto user
		handleClosePlanModal();
	};





	const fetchAddressByCep = async (cep, setFieldValue) => {
		try {
			const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
			if (response.data.erro) {
				toast.error("CEP inválido");
				return;
			}
			const { logradouro, bairro, localidade, uf } = response.data;

			// Preencher automaticamente os campos do formulário
			setFieldValue("street", logradouro);
			setFieldValue("neighborhood", bairro);
			setFieldValue("city", localidade);
			setFieldValue("state", uf);
		} catch (error) {
			toast.error("Erro ao buscar CEP");
		}
	};

	const checkExistingUser = async (email, cpfCnpj) => {
		try {
		  const response = await api.post("/companies/validate", {
			email,
			cpfCnpj
		  });
		  return false; // Retorna os dados da API, que pode ser um status ou uma mensagem
		} catch (err) {
			console.log({err});
			
		  if (err.response && err.response.status === 400) {
			return err.response.data?.error || true; // Se a API retornar erro (ex: email ou CPF/CNPJ duplicados)
		  } else {
			throw new Error("Erro ao verificar os dados."); // Lidar com outros erros inesperados
		  }
		}
	  };
	  



	return (
		<div className={classes.root}>

			<div className={classes.formSection}>
				<Container component="main" maxWidth="xl" className={classes.formContainer}>
					<CssBaseline />
					<Typography component="h1" variant="h5" className={classes.title}>
						Registrar
					</Typography>
					<Formik
						key={currentStep} // Adicione esta linha
						initialValues={formValues}
						validationSchema={validationSchemas[currentStep]}
						onSubmit={async  (values, actions) => {
							// Verificar se estamos no primeiro step (Dados Pessoais)
							if (currentStep === 0) {
								try {
									// Chamar a função para verificar se o email e CPF/CNPJ já estão cadastrados
									const isExistingUser = await checkExistingUser(values.email, values.cpfCnpj);

									if (isExistingUser) {
										// Se o usuário já existe, lançar erro e impedir o avanço
										let errorMsg = typeof isExistingUser == 'string' ? isExistingUser : "Email ou CPF/CNPJ já cadastrados.";
										toast.error(errorMsg);
										actions.setSubmitting(false); // Parar o estado de envio
									} else {
										// Se não existir, continuar para o próximo step
										setFormValues(values); // Salva os valores atuais
										setCurrentStep(currentStep + 1);
										actions.setTouched({});
										actions.setSubmitting(false);
									}
								} catch (error) {
									toastError(error);
									actions.setSubmitting(false);
								}
							} else {
								// Caso contrário, se não for o primeiro step, apenas continuar normalmente
								if (currentStep < steps.length - 1) {
									setFormValues(values); // Salva os valores atuais
									setCurrentStep(currentStep + 1);
									actions.setTouched({});
									actions.setSubmitting(false);
								} else {
									handleSignUp(values); // Enviar os dados de cadastro no último step
								}
							}
						}}
					>
						{({ touched, errors, values, setFieldValue }) => (
							<Form className={classes.form}>
								<Stepper activeStep={currentStep} alternativeLabel>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>

								{currentStep === 0 && (
									<Grid container spacing={2}>
										{/* Seção de Dados Pessoais */}
										<Grid item xs={12}>
											<FormControl variant="outlined" fullWidth required className={classes.textField}>
												<InputLabel id="personType-label">Tipo de Pessoa</InputLabel>
												<Field
													as={Select}
													labelId="personType-label"
													id="personType"
													name="personType"
													label="Tipo de Pessoa"
												>
													<MenuItem value="Física">Pessoa Física</MenuItem>
													<MenuItem value="Jurídica">Pessoa Jurídica</MenuItem>
												</Field>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<Field
												as={TextField}
												autoComplete="name"
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												fullWidth
												id="name"
												label={values.personType === "Física" ? "Nome Completo" : "Razão Social"}
												className={classes.textField}
											/>
										</Grid>
										<Grid item xs={12}>
											<Field name="cpfCnpj">
												{({ field, form: { setFieldValue } }) => (
													<InputMask
														{...field}
														mask={values.personType === "Física" ? "999.999.999-99" : "99.999.999/9999-99"}
														onChange={(e) => setFieldValue("cpfCnpj", e.target.value)}
													>
														{(inputProps) => (
															<TextField
																{...inputProps}
																variant="outlined"
																fullWidth
																label={values.personType === "Física" ? "CPF" : "CNPJ"}
																error={touched.cpfCnpj && Boolean(errors.cpfCnpj)}
																helperText={touched.cpfCnpj && errors.cpfCnpj}
																className={classes.textField}
															/>
														)}
													</InputMask>
												)}
											</Field>
										</Grid>
										<Grid item xs={12}>
											<Field name="phone">
												{({ field, form: { setFieldValue } }) => (
													<InputMask
														{...field}
														mask="(99) 99999-9999"
														onChange={(e) => setFieldValue("phone", e.target.value)}
													>
														{(inputProps) => (
															<TextField
																{...inputProps}
																variant="outlined"
																fullWidth
																label="Telefone"
																error={touched.phone && Boolean(errors.phone)}
																helperText={touched.phone && errors.phone}
																className={classes.textField}
															/>
														)}
													</InputMask>
												)}
											</Field>
										</Grid>
										<Grid item xs={12}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												id="email"
												label={i18n.t("signup.form.email")}
												name="email"
												error={touched.email && Boolean(errors.email)}
												helperText={touched.email && errors.email}
												autoComplete="email"
												className={classes.textField}
												required
											/>
										</Grid>
										<Grid item xs={12}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												name="password"
												error={touched.password && Boolean(errors.password)}
												helperText={touched.password && errors.password}
												label={i18n.t("signup.form.password")}
												type={showPassword ? "text" : "password"}
												id="password"
												autoComplete="current-password"
												required
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
															>
																{showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													),
												}}
												className={classes.textField}
											/>
										</Grid>
									</Grid>
								)}

								{currentStep === 1 && (

									<Grid container spacing={2}>
										{/* Seção de Endereço */}
										<Grid item xs={12} sm={4}>
											<Field name="zipcode">
												{({ field, form: { setFieldValue } }) => (
													<InputMask
														{...field}
														mask="99999-999"
														onKeyUp={async (e) => {
															const cep = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
															if (cep.length === 8) {
																await fetchAddressByCep(cep, setFieldValue);
															}
														}}
														onChange={(e) => setFieldValue('zipcode', e.target.value)}
													>
														{(inputProps) => (
															<TextField
																{...inputProps}
																variant="outlined"
																fullWidth
																id="zipcode"
																label="CEP"
																error={touched.zipcode && Boolean(errors.zipcode)}
																helperText={touched.zipcode && errors.zipcode}
																className={classes.textField}
															/>
														)}
													</InputMask>
												)}
											</Field>
										</Grid>


										<Grid item xs={12}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												id="street"
												label="Rua"
												name="street"
												error={touched.street && Boolean(errors.street)}
												helperText={touched.street && errors.street}
												className={classes.textField}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												id="number"
												label="Número"
												name="number"
												error={touched.number && Boolean(errors.number)}
												helperText={touched.number && errors.number}
												className={classes.textField}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												id="neighborhood"
												label="Bairro"
												name="neighborhood"
												error={touched.neighborhood && Boolean(errors.neighborhood)}
												helperText={touched.neighborhood && errors.neighborhood}
												className={classes.textField}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Field
												as={TextField}
												variant="outlined"
												fullWidth
												id="city"
												label="Cidade"
												name="city"
												error={touched.city && Boolean(errors.city)}
												helperText={touched.city && errors.city}
												className={classes.textField}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={3}>
											<FormControl variant="outlined" fullWidth required className={classes.textField}>
												<InputLabel id="state-label">Estado</InputLabel>
												<Field
													as={Select}
													labelId="state-label"
													id="state"
													name="state"
													label="Estado"
													value={values.state}
													onChange={(e) => setFieldValue('state', e.target.value)}
												>
													{/* Lista de estados */}
													<MenuItem value="AC">AC</MenuItem>
													<MenuItem value="AL">AL</MenuItem>
													<MenuItem value="AP">AP</MenuItem>
													<MenuItem value="AM">AM</MenuItem>
													<MenuItem value="BA">BA</MenuItem>
													<MenuItem value="CE">CE</MenuItem>
													<MenuItem value="DF">DF</MenuItem>
													<MenuItem value="ES">ES</MenuItem>
													<MenuItem value="GO">GO</MenuItem>
													<MenuItem value="MA">MA</MenuItem>
													<MenuItem value="MT">MT</MenuItem>
													<MenuItem value="MS">MS</MenuItem>
													<MenuItem value="MG">MG</MenuItem>
													<MenuItem value="PA">PA</MenuItem>
													<MenuItem value="PB">PB</MenuItem>
													<MenuItem value="PR">PR</MenuItem>
													<MenuItem value="PE">PE</MenuItem>
													<MenuItem value="PI">PI</MenuItem>
													<MenuItem value="RJ">RJ</MenuItem>
													<MenuItem value="RN">RN</MenuItem>
													<MenuItem value="RS">RS</MenuItem>
													<MenuItem value="RO">RO</MenuItem>
													<MenuItem value="RR">RR</MenuItem>
													<MenuItem value="SC">SC</MenuItem>
													<MenuItem value="SP">SP</MenuItem>
													<MenuItem value="SE">SE</MenuItem>
													<MenuItem value="TO">TO</MenuItem>
												</Field>
											</FormControl>
										</Grid>

									</Grid>
								)}

								{currentStep === 2 && (
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography variant="h6" align="center">
												Selecione o Melhor Plano para Você
											</Typography>
										</Grid>
										{plans.map((plan) => (
											<Grid item xs={12} sm={6} md={4} key={plan.id}>
												<Card
													className={classes.card}
													style={{
														borderRadius: '12px',
														boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
														border: values.planId === plan.id ? '2px solid #0ab671' : '',
														cursor: 'pointer',
													}}
													onClick={() => {
														setSelectedPlan({ id: plan.id, name: plan.name, price: plan.value });
														setFieldValue('planId', plan.id);
													}}
												>
													<CardContent
														style={{
															backgroundColor: '#f5f5f5',
															padding: '20px',
															textAlign: 'center',
														}}
													>
														<Typography variant="h6" style={{ fontWeight: 'bold' }}>
															{plan.name}
														</Typography>
														<Typography variant="body2" style={{ margin: '10px 0' }}>
															WhatsApps: <strong>{plan.connections}</strong>
														</Typography>
														<Typography variant="body2" style={{ marginBottom: '10px' }}>
															Usuários: <strong>{plan.users}</strong>
														</Typography>
														<Typography variant="h6" color="primary">
															R$ {plan.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
										))}
										{touched.planId && errors.planId && (
											<Grid item xs={12}>
												<Typography color="error" variant="body2" align="center">
													{errors.planId}
												</Typography>
											</Grid>
										)}
									</Grid>
								)}

								<div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
									{currentStep > 0 && (
										<Button
											variant="contained"
											onClick={() => setCurrentStep(currentStep - 1)}
											className={classes.submit}
										>
											Voltar
										</Button>
									)}
									<Button
										type="submit"
										variant="contained"
										className={classes.submit}
									>
										{currentStep === steps.length - 1 ? 'Enviar' : 'Próximo'}
									</Button>
								</div>

								{currentStep === steps.length - 1 && (
									<Grid container justify="flex-end">
										<Grid item>
											<Link
												href="#"
												variant="body2"
												component={RouterLink}
												to="/login"
												className={classes.link}
											>
												{i18n.t("signup.buttons.login")}
											</Link>
										</Grid>
									</Grid>
								)}
							</Form>
						)}
					</Formik>
				</Container>
			</div>
		</div>
	);
};

export default SignUp;
