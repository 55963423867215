import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarSkeleton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  checkboxSkeleton: {
    textAlign: "center",
  },
}));

const TableRowSkeleton = ({ avatar, columns, useCheckBox = true }) => {
  const classes = useStyles();

  return (
    <TableRow>
      {/* Coluna de checkbox */}

    {
      useCheckBox && (
        <TableCell align="center" className={classes.checkboxSkeleton}>
        <Skeleton variant="rect" width={24} height={24} animation="wave" />
      </TableCell>
      )
    }

      {/* Coluna de avatar */}
      {avatar && (
        <TableCell className={classes.avatarSkeleton}>
          <Skeleton
            animation="wave"
            variant="circle"
            width={40}
            height={40}
          />
        </TableCell>
      )}

      {/* Colunas adicionais */}
      {Array.from({ length: columns }, (_, index) => (
        <TableCell align="center" key={index}>
          <div className={classes.customTableCell}>
            <Skeleton animation="wave" height={30} width="80%" />
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableRowSkeleton;
