import React, { useState } from "react";
import {
  Button,
  Paper,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Add, Remove } from "@material-ui/icons";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({

  root: {
    padding: theme.spacing(4),
    maxWidth: "100%",
    borderRadius: "12px",
},
tableContainer: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(3),
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  changeRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const CreatePatchNotes = () => {
  const classes = useStyles();
  const history = useHistory();

  const [formValues, setFormValues] = useState({
    version: "",
    releaseDate: "",
    description: "",
    status: "lançada",
  });
  const [changes, setChanges] = useState([{ title: "", detail: "" }]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChangeRow = (index, field, value) => {
    const updatedChanges = [...changes];
    updatedChanges[index][field] = value;
    setChanges(updatedChanges);
  };

  const handleAddChange = () => {
    setChanges([...changes, { title: "", detail: "" }]);
  };

  const handleRemoveChange = (index) => {
    const updatedChanges = changes.filter((_, i) => i !== index);
    setChanges(updatedChanges);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        ...formValues,
        changes,
      };
      await api.post("/patch-notes", payload);
      toast.success("Patch Note criada com sucesso!");
      history.push("/patch-notes");
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.tableContainer}>
    <Paper className={classes.root}>
  
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          Criar Nova Patch Note
        </Typography>
        <Button
          variant="contained"
          className={classes.addButton}
          onClick={() => history.push("/patch-notes")}
        >
          Voltar para Listagem
        </Button>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          name="version"
          label="Versão"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formValues.version}
          onChange={handleChange}
          helperText="Digite a versão no formato X.X.X (e.g., 1.0.0)"
        />
        <TextField
          name="releaseDate"
          label="Data de Lançamento"
          type="date"
          variant="outlined"
          fullWidth
          required
          className={classes.formField}
          value={formValues.releaseDate}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name="description"
          label="Descrição"
          variant="outlined"
          fullWidth
          required
          multiline
          rows={4}
          className={classes.formField}
          value={formValues.description}
          onChange={handleChange}
          helperText="Forneça uma descrição detalhada da atualização."
        />
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Mudanças
        </Typography>
        {changes.map((change, index) => (
          <div key={index} className={classes.changeRow}>
            <TextField
              label="Título"
              variant="outlined"
              required
              value={change.title}
              onChange={(e) => handleChangeRow(index, "title", e.target.value)}
              style={{ flex: 1 }}
            />
            <TextField
              label="Detalhes"
              variant="outlined"
              required
              value={change.detail}
              onChange={(e) => handleChangeRow(index, "detail", e.target.value)}
              style={{ flex: 2 }}
            />
            <IconButton
              color="secondary"
              onClick={() => handleRemoveChange(index)}
              disabled={changes.length === 1} // Impede remoção se for o último
            >
              <Remove />
            </IconButton>
          </div>
        ))}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddChange}
          className={classes.formField}
        >
          Adicionar Mudança
        </Button>
        <TextField
          name="status"
          label="Status"
          variant="outlined"
          fullWidth
          select
          required
          className={classes.formField}
          value={formValues.status}
          onChange={handleChange}
        >
          <MenuItem value="lançada">Lançada</MenuItem>
          <MenuItem value="beta">Beta</MenuItem>
          <MenuItem value="planejada">Planejada</MenuItem>
        </TextField>

        <div className={classes.formActions}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => history.push("/patch-notes")}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Salvar"}
          </Button>
        </div>
      </form>

    </Paper>
    </div>
  );
};

export default CreatePatchNotes;
