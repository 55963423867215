import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  TablePagination,
  TextField,
} from "@material-ui/core";

const ParticipantsTable = ({ groupDetails, handleRemoveParticipant }) => {
  const [page, setPage] = useState(0); // Página atual
  const [rowsPerPage, setRowsPerPage] = useState(5); // Linhas por página
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca

  // Filtra os participantes com base no termo de busca
  const filteredParticipants = groupDetails.participants.filter(
    (participant) =>
      participant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      participant.id.toString().includes(searchTerm)
  );

  // Define os participantes exibidos com base na página atual e no número de linhas por página
  const paginatedParticipants = filteredParticipants.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Volta para a primeira página ao mudar o número de linhas
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Volta para a primeira página ao filtrar
  };

  return (
    <div>
      {/* Campo de busca */}
      <div style={{ marginBottom: "16px" }}>
        <TextField
          label="Buscar participante"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Digite o nome ou ID"
        />
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Foto</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedParticipants.map((participant) => (
              <TableRow key={participant.id}>
                <TableCell>
                  <Avatar
                    src={
                      participant.foto ||
                      "https://cdn-icons-png.flaticon.com/512/126/126322.png"
                    }
                    alt={participant.name}
                  />
                </TableCell>
                <TableCell>{participant.name || participant.id}</TableCell>
                <TableCell>{participant.id}</TableCell>
                <TableCell>{participant.isAdmin ? "Sim" : "Não"}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleRemoveParticipant(participant.id)}
                  >
                    Remover
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Componente de paginação */}
      <TablePagination
        component="div"
        count={filteredParticipants.length} // Total de participantes filtrados
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
      />
    </div>
  );
};

export default ParticipantsTable;
