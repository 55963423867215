// CreditCardForm.js
import React from "react";
import { TextField, Grid } from "@material-ui/core";
import InputMask from "react-input-mask";

export default function CreditCardForm({ setFieldValue, values }) {
  const handleInputChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputMask
          mask="9999 9999 9999 9999"
          value={values.cardNumber || ""}
          onChange={handleInputChange}
        >
          {() => (
            <TextField
              required
              label="Número do Cartão"
              fullWidth
              name="cardNumber"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="99/99"
          value={values.cardExpiration || ""}
          onChange={handleInputChange}
        >
          {() => (
            <TextField
              required
              label="Validade (MM/AA)"
              fullWidth
              name="cardExpiration"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="999"
          value={values.cardCVV || ""}
          onChange={handleInputChange}
        >
          {() => (
            <TextField
              required
              label="CVV"
              fullWidth
              name="cardCVV"
              type="password"
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="Nome no Cartão"
          fullWidth
          name="cardHolderName"
          value={values.cardHolderName || ""}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
}
