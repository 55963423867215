import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Gráfico de Modulos",
      position: "left",
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "end",
      color: "#fff",
      backgroundColor: "#4CAF50", // Fundo para destacar o número
      borderRadius: 3,
      padding: 4,
      font: {
        size: 16,
        weight: "bold",
      },
      formatter: (value) => value, // Exibe o valor numérico diretamente
    },
  },
};

const ModuloChart = ({ modulos }) => {
  const [barData, setBarData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    // Filtra os módulos com 0 tickets para a categoria "Outros"
    const filteredData = modulos.reduce(
      (acc, modulo) => {
        const tickets = parseInt(modulo.ticketTrakingCount);
        if (tickets === 0) {
          acc.others += 1;
        } else {
          acc.data.push({
            nome: modulo.nome,
            tickets,
          });
        }
        return acc;
      },
      { data: [], others: 0 }
    );

    if (filteredData.others > 0) {
      filteredData.data.push({
        nome: "Outros",
        tickets: filteredData.others,
      });
    }

    // Configura os dados do gráfico
    setBarData({
      labels: filteredData.data.map((item) => item.nome),
      datasets: [
        {
          data: filteredData.data.map((item) => item.tickets),
          backgroundColor: "#2DDD7F",
        },
      ],
    });
  }, [modulos]);

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Gráfico de Modulos
      </Typography>
      <Bar options={options} data={barData} style={{ maxWidth: "100%", maxHeight: "600px" }} />
    </>
  );
};

export default ModuloChart;
