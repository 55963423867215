import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
	ticketHeader: {
		display: "flex",
		backgroundColor: theme.palette.tabHeaderBackground,  // Cor de fundo escura
		flex: "none",
		borderBottom: "1px solid rgba(255, 255, 255, 0.12)",  // Borda clara para modo escuro
	},
}));

const TicketHeaderSkeleton = () => {
	const classes = useStyles();

	return (
		<Card square className={classes.ticketHeader}>
			<CardHeader
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={
					<Skeleton
						animation="wave"
						variant="circle"
						classes={{ root: classes.skeleton }}  // Aplicar cor do skeleton
					>
						<Avatar alt="contact_image" />
					</Skeleton>
				}
				title={<Skeleton animation="wave" width={80} classes={{ root: classes.skeleton }} />}
				subheader={<Skeleton animation="wave" width={140} classes={{ root: classes.skeleton }} />}
			/>
		</Card>
	);
};

export default TicketHeaderSkeleton;
