import React from "react";
import { Button, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

export default function PaymentMethodForm({ setFieldValue, values }) {
  const handleChange = (event) => {
    setFieldValue("paymentMethod", event.target.value);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Escolha o método de pagamento
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup name="paymentMethod" value={values.paymentMethod || ""} onChange={handleChange}>
          <FormControlLabel value="pix" control={<Radio />} label="Pix" />
          <FormControlLabel value="creditCard" control={<Radio />} label="Cartão de Crédito" />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
