import React, { useState } from 'react';
import api from "../../services/api";
import TarefaDialog from './TarefaDialog';

const CustomCard = ({ id, title, description, label }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tarefaDetalhes, setTarefaDetalhes] = useState(null);

  // Função para abrir o diálogo e buscar os detalhes da tarefa
  const handleTitleClick = async () => {
    try {
      const response = await api.get(`/tarefa/${id}`);
      setTarefaDetalhes(response.data);
      setDialogOpen(true); // Abre o diálogo
    } catch (error) {
      console.error("Erro ao buscar detalhes da tarefa:", error);
    }
  };

  // Função para fechar o diálogo
  const closeDialog = () => {
    setDialogOpen(false);
    setTarefaDetalhes(null);
  };

  return (
    <>
      <div style={{
        padding: '15px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #ddd',
        marginBottom: '15px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        maxHeight: '250px',
        width: '350px',
        overflow: 'hidden',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
        }}>
          <h3 
            style={{ fontSize: '16px', fontWeight: 'bold', margin: 0, cursor: 'pointer' }} 
            onClick={handleTitleClick}
          >
            {title}
          </h3>
          {label && <span style={{
            fontSize: '12px',
            padding: '4px 8px',
            backgroundColor: '#e0e0e0',
            borderRadius: '12px'
          }}>{label}</span>}
        </div>
        <div
          style={{
            fontSize: '14px',
            color: '#555',
            lineHeight: '1.6',
            overflow: 'hidden',
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      {/* TarefaDialog component to show the task details */}
      {tarefaDetalhes && (
        <TarefaDialog 
          open={dialogOpen} 
          onClose={closeDialog} 
          tarefaDetalhes={tarefaDetalhes} 
        />
      )}
    </>
  );
};

export default CustomCard;
