import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f4f6f8",
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    maxWidth: 500,
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  },
  iconContainer: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 80,
    color: theme.palette.success.main,
  },
  messageContainer: {
    marginBottom: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(1.5, 4),
    fontSize: "1rem",
  },
}));

const PaymentSuccess = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToDashboard = () => {
    history.push("/"); // Altere para a rota do painel da sua aplicação
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.iconContainer}>
          <CheckCircleIcon className={classes.icon} />
        </div>
        <Typography variant="h5" gutterBottom color="primary">
          Pagamento Confirmado!
        </Typography>
        <div className={classes.messageContainer}>
          <Typography variant="body1" color="textSecondary">
            Obrigado por concluir o pagamento. Seu acesso foi ativado, e agora você pode aproveitar todas as funcionalidades.
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoToDashboard}
          className={classes.button}
        >
          Acessar o Painel
        </Button>
      </Paper>
    </div>
  );
};

export default PaymentSuccess;
