import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: "100%",
    borderRadius: "12px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainer: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
    "& th": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const EtapasTable = () => {
  const classes = useStyles();
  const [etapas, setEtapas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [newEtapa, setNewEtapa] = useState({ nome: "", ordem: "" });
  const [selectedEtapa, setSelectedEtapa] = useState(null);

  const fetchEtapas = async () => {
    setLoading(true);
    try {
      const response = await api.get("/tarefas/etapas");
      setEtapas(response.data);
      setError(null);
    } catch (error) {
      console.error("Erro ao buscar etapas:", error);
      if (error.response && error.response.data.error === "Nenhuma etapa encontrada para esta empresa.") {
        setEtapas([]);
      } else {
        setError("Erro ao carregar as etapas.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEtapas();
  }, []);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewEtapa({ nome: "", ordem: "" });
  };

  const handleCreateEtapa = async () => {
    try {
      const response = await api.post("/tarefas/etapas", {
        nome: newEtapa.nome,
        ordem: parseInt(newEtapa.ordem),
      });
      setEtapas([...etapas, response.data]);
      handleCloseDialog();
    } catch (error) {
      console.error("Erro ao cadastrar nova etapa:", error);
      setError("Erro ao cadastrar a etapa.");
    }
  };

  const handleOpenEditDialog = (etapa) => {
    setSelectedEtapa(etapa);
    setNewEtapa({ nome: etapa.nome, ordem: etapa.ordem });
    setEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialog(false);
    setNewEtapa({ nome: "", ordem: "" });
  };

  const handleEditEtapa = async () => {
    try {
      const response = await api.put(`/tarefas/etapas/${selectedEtapa.id}`, {
        nome: newEtapa.nome,
        ordem: parseInt(newEtapa.ordem),
      });
      setEtapas(
        etapas.map((etapa) => (etapa.id === selectedEtapa.id ? response.data : etapa))
      );
      handleCloseEditDialog();
    } catch (error) {
      console.error("Erro ao editar etapa:", error);
      setError("Erro ao editar a etapa.");
    }
  };

  const handleDeleteEtapa = async (id) => {
    try {
      await api.delete(`/tarefas/etapas/${id}`);
      setEtapas(etapas.filter((etapa) => etapa.id !== id));
    } catch (error) {
      let errorMsg = error.response.data.error || "Erro ao excluir a etapa.";
      console.error("Erro ao excluir etapa:", error);
      setError(errorMsg);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <p>{error}</p>;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" color="textPrimary" fontWeight="bold">
          Etapas da Tarefa
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          className={classes.addButton}
        >
          Adicionar Nova Etapa
        </Button>
      </div>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Ordem</TableCell>
              <TableCell>Tarefas</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {etapas.length > 0 ? (
              etapas.map((etapa) => (
                <TableRow key={etapa.id}>
                  <TableCell>{etapa.id}</TableCell>
                  <TableCell>{etapa.nome}</TableCell>
                  <TableCell>{etapa.ordem}</TableCell>
                  <TableCell>{etapa.tarefasCount}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenEditDialog(etapa)} color="primary">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteEtapa(etapa.id)} color="secondary">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Nenhuma etapa encontrada.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Diálogo para adicionar nova etapa */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Nova Etapa</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            margin="dense"
            label="Nome da Etapa"
            type="text"
            fullWidth
            value={newEtapa.nome}
            onChange={(e) => setNewEtapa({ ...newEtapa, nome: e.target.value })}
            className={classes.textField}
          />
          <TextField
            margin="dense"
            label="Ordem"
            type="number"
            fullWidth
            value={newEtapa.ordem}
            onChange={(e) => setNewEtapa({ ...newEtapa, ordem: e.target.value })}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCreateEtapa} color="primary">
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para editar etapa */}
      <Dialog open={editDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Editar Etapa</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            margin="dense"
            label="Nome da Etapa"
            type="text"
            fullWidth
            value={newEtapa.nome}
            onChange={(e) => setNewEtapa({ ...newEtapa, nome: e.target.value })}
            className={classes.textField}
          />
          <TextField
            margin="dense"
            label="Ordem"
            type="number"
            fullWidth
            value={newEtapa.ordem}
            onChange={(e) => setNewEtapa({ ...newEtapa, ordem: e.target.value })}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseEditDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditEtapa} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EtapasTable;
