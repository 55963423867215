import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid"; 
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { versionSystem } from "../../../package.json";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { nomeEmpresa } from "../../../package.json";
import { AuthContext } from "../../context/Auth/AuthContext";

const Copyright = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="#">
				RODTICKET - v 1.0
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		width: "100vw",
		height: "100vh",
		backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#ffffff", // Fundo adaptado ao modo
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column", // Coloca o form embaixo da imagem em telas pequenas
		},
	},
	imageSection: {
		flex: 2,
		backgroundColor: theme.palette.mode === "dark" ? "#0ab671" : "#0ab671", // Cor de fundo adaptada
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			display: "none", // Esconde a imagem em telas pequenas
		},
	},
	image: {
		width: "60%",
		[theme.breakpoints.down("md")]: {
			width: "80%", // Aumenta a imagem em telas médias
		},
	},
	formSection: {
		flex: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ebfff1", // Cor de fundo adaptada
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#0ab671", // Fundo verde/escuro em telas pequenas
			flex: 1,
			width: "100vw", // Faz o formulário ocupar 100% da largura
			height: "100vh", // Ocupa 100% da altura em telas pequenas
			padding: theme.spacing(1), // Reduz o padding em telas pequenas
		},
	},
	formContainer: {
		width: "100%",
		maxWidth: "400px",
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#fff", // Fundo adaptado ao modo
		borderRadius: "10px",
		boxShadow:
			theme.palette.mode === "dark"
				? "0px 4px 10px rgba(0, 0, 0, 0.5)"
				: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Sombras adaptadas
		padding: theme.spacing(4),
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(3),
			boxShadow: "none",
			borderRadius: "10px",
		},
	},
	title: {
		marginBottom: theme.spacing(4),
		color: theme.palette.mode === "dark" ? "#fff" : "#2C2C2C", // Texto adaptado ao modo
		fontWeight: "bold",
	},
	form: {
		width: "100%",
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: theme.palette.mode === "dark" ? "#0ab671" : "#0ab671",
		color: "#fff",
		"&:hover": {
			backgroundColor: theme.palette.mode === "dark" ? "#02532c" : "#02532c",
		},
	},
	textField: {
		marginBottom: theme.spacing(2),
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.mode === "dark" ? "#555" : "#ddd",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.mode === "dark" ? "#0ab671" : "#0ab671",
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.mode === "dark" ? "#0ab671" : "#0ab671",
			},
			"& input": {
				color: theme.palette.mode === "dark" ? "#fff" : "#555",
			},
		},
	},
	link: {
		color: "#0ab671",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	logo: {
		width: "80px",
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("sm")]: {
			width: "100px",
		},
	},
}));


const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);
	const [viewregister, setviewregister] = useState("enabled");

	const handleChangeInput = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		fetchviewregister();
	}, []);

	const fetchviewregister = async () => {
		try {
			const responsev = await api.get("/settings/viewregister");
			const viewregisterX = responsev?.data?.value;
			setviewregister(viewregisterX);
		} catch (error) {
			console.error("Error retrieving viewregister", error);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleLogin(user);
	};

	// Adiciona o logo com random cache busting
	const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;
	const logo2 = `/og.png`;
	const randomValue = Math.random();
	const logoWithRandom = `${logo}?r=${randomValue}`;

	return (
		<div className={classes.root}>
			<div className={classes.imageSection}>
				<img
					src={logo2}
					alt="Whaticket Logo"
					className={classes.image}
				/>
			</div>
			<div className={classes.formSection}>
				<Container component="main" maxWidth="xs" className={classes.formContainer}>
					<CssBaseline />
					{/* Logo no topo do formulário */}

					<Typography component="h1" variant="h5" className={classes.title}>
						Entrar
					</Typography>
					<form className={classes.form} noValidate onSubmit={handleSubmit}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							value={user.email}
							onChange={handleChangeInput}
							autoComplete="email"
							autoFocus
							className={classes.textField}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Senha"
							type="password"
							id="password"
							value={user.password}
							onChange={handleChangeInput}
							autoComplete="current-password"
							className={classes.textField}
						/>
						<Grid container>
							<Grid item xs>
								<Link
									component={RouterLink}
									to="/forgetpsw"
									variant="body2"
									className={classes.link}
								>
									Esqueceu a senha?
								</Link>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							className={classes.submit}
						>
							Entrar
						</Button>
						{viewregister === "enabled" && (
							<Grid container justifyContent="center">
								<Grid item>
									<Link
										component={RouterLink}
										to="/signup"
										variant="body2"
										className={classes.link}
									>
										Criar conta
									</Link>
								</Grid>
							</Grid>
						)}
					</form>
					<Box mt={5}>
						<Copyright />
					</Box>
				</Container>
			</div>
		</div>
	);
};

export default Login;
