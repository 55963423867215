import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CreateIcon from '@material-ui/icons/Create';

import { i18n } from "../../translate/i18n";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader } from "@material-ui/core";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";
import { Box, Modal } from "@mui/material";
import { generateColor } from "../../helpers/colorGenerator";
import { getInitials } from "../../helpers/getInitials";
import api from "../../services/api";
import EnhancedModal from "./EnhancedModal";
const drawerWidth = 320;


const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,

	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [protocolo, setProtocolo] = useState('');
	const [contactData, setContactData] = useState({});

	const handleOpen = async (ticket) => {


		console.log({ ticket });

		const { data } = await api.get(`/contacts/${ticket.id}/image`);
		setContactData(data);
		console.log({ data });

		setOpenModal(true)

	};
	const handleClose = () => setOpenModal(false);
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	};
	useEffect(() => {
		setOpenForm(false);

		//console.log({ contact });

		if (ticket && ticket.ticketTrakings) {
			setProtocolo(ticket?.ticketTrakings[0].protocolo);
		}




	}, [open, contact, ticket]);

	return (
		<>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="right"
				open={open}
				PaperProps={{ style: { position: "absolute" } }}
				BackdropProps={{ style: { position: "absolute" } }}
				ModalProps={{
					container: document.getElementById("drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.header}>
					<IconButton onClick={handleDrawerClose}>
						<CloseIcon />
					</IconButton>
					<Typography style={{ justifySelf: "center" }}>
						{i18n.t("contactDrawer.header")}
					</Typography>
				</div>
				{loading ? (
					<ContactDrawerSkeleton classes={classes} />
				) : (
					<div className={classes.content}>
						<Paper square variant="outlined" className={classes.contactHeader}>
							<>
								<CardHeader
									onClick={() => { }}
									style={{ cursor: "pointer", width: '100%' }}
									titleTypographyProps={{ noWrap: true }}
									subheaderTypographyProps={{ noWrap: true }}
									avatar={
										<Avatar
											src={contact.profilePicUrl}
											alt="contact_image"
											style={{ width: 60, height: 60 }}
											onClick={() => { handleOpen(ticket) }} // Abre o modal ao clicar na imagem
										/>
									}
									title={
										<>
											<Typography onClick={() => setOpenForm(true)}>
												{contact.name}
												<CreateIcon style={{ fontSize: 16, marginLeft: 5 }} />
											</Typography>
										</>
									}
									subheader={
										<>
											<Typography style={{ fontSize: 12 }}>
												<Link href={`tel:${contact.number}`}>{contact.number}</Link>
											</Typography>
											<Typography style={{ fontSize: 12 }}>
												<Link href={`mailto:${contact.email}`}>{contact.email}</Link>
											</Typography>
											<Typography style={{ fontSize: 12 }}>

											</Typography>
										</>
									}
								/>

								{/* Modal para exibir a imagem do perfil */}
								<EnhancedModal
									openModal={openModal}
									handleClose={handleClose}
									contactData={{
										profilePicUrl: contactData?.profilePicUrl || "https://via.placeholder.com/150",
										contactName: contact?.name || "Nome Desconhecido",
									}}
								/>

							</>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => setModalOpen(!openForm)}
								style={{ fontSize: 12 }}
							>
								{i18n.t("contactDrawer.buttons.edit")}
							</Button>
							{(contact.id && openForm) && <ContactForm initialContact={contact} onCancel={() => setOpenForm(false)} />}
						</Paper>



						<Paper
							square
							variant="outlined"
							className={classes.contactDetails}
							style={{ padding: 16, backgroundColor: "#f9f9f9", cursor: "pointer" }}
							onClick={() => {
								navigator.clipboard.writeText(protocolo || "Carregando...");
								toast.success("Protocolo copiado para a área de transferência!");
							}}
						>
							<Typography
								variant="subtitle1"
								style={{ marginBottom: 10, fontWeight: "bold", color: "#333", textAlign: "center" }}
							>
								Detalhes do Protocolo
							</Typography>
							<Typography
								style={{ fontSize: 14, color: "#555", textAlign: "center" }}
							>
								Protocolo:
								<span style={{ fontWeight: 500, color: "#000" }}>
									{protocolo || "Carregando..."}
								</span>
							</Typography>
							<Typography
								variant="caption"
								style={{ marginTop: 8, color: "#777", textAlign: "center", display: "block" }}
							>
								Clique para copiar
							</Typography>
						</Paper>

						<Paper
							square
							variant="outlined"
							className={classes.contactDetails}
							style={{
								padding: 16,
								backgroundColor: "#f9f9f9",
								textAlign: "center",
								cursor: "pointer",
							}}
						>
							<Typography
								variant="subtitle1"
								style={{
									marginBottom: 10,
									fontWeight: "bold",
									color: "#333",
								}}
							>
								Solicitar Avaliação
							</Typography>
							<Typography
								style={{
									fontSize: 14,
									color: "#555",
									marginBottom: 16,
								}}
							>
								Clique no botão abaixo para solicitar uma avaliação do atendimento.
							</Typography>
							<button
								style={{
									padding: "10px 20px",
									fontSize: "14px",
									fontWeight: "bold",
									color: "#fff",
									backgroundColor: "#4caf50",
									border: "none",
									borderRadius: "5px",
									cursor: "pointer",
								}}
								onClick={async () => {
									// Aqui vai a lógica para solicitar avaliação
									toast.success("Solicitação de avaliação enviada com sucesso!");

									const { data } = await api.post(`/ticket/${ticket?.id}/sendRating`);

								}}
							>
								Solicitar Avaliação
							</button>
						</Paper>



						<Paper square variant="outlined" className={classes.contactDetails}>
							<Typography variant="subtitle1" style={{ marginBottom: 10 }}>
								{i18n.t("ticketOptionsMenu.appointmentsModal.title")}
							</Typography>
							<ContactNotes ticket={ticket} />
						</Paper>
						<Paper square variant="outlined" className={classes.contactDetails}>
							<ContactModal
								open={modalOpen}
								onClose={() => setModalOpen(false)}
								contactId={contact.id}
							></ContactModal>
							<Typography variant="subtitle1">
								{i18n.t("contactDrawer.extraInfo")}
							</Typography>
							{contact?.extraInfo?.map(info => (
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel>{info.name}</InputLabel>
									<Typography component="div" noWrap style={{ paddingTop: 2 }}>
										<MarkdownWrapper>{info.value}</MarkdownWrapper>
									</Typography>
								</Paper>
							))}
						</Paper>
					</div>
				)}
			</Drawer>
		</>
	);
};

export default ContactDrawer;
