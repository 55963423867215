import React, { useState } from 'react';

const ImagePreview = ({ message, onImageLoad, handleImageClick, imageIndex }) => {
  const [imageSize, setImageSize] = useState({ width: 'auto', height: 'auto' });
  const [loading, setLoading] = useState(true);
  const [boxHeight, setBoxHeight] = useState('200px'); // Altura inicial da caixa

  const handleImageLoadInternal = (e) => {
    const { naturalWidth, naturalHeight } = e.target;

    // Ajusta a altura da caixa para ficar em volta da imagem
    setBoxHeight('auto');
    setImageSize({
      width: naturalWidth > 400 ? '100%' : 'auto', // Largura dinâmica (limite de 400px)
      height: naturalHeight > 400 ? '100%' : 'auto', // Altura dinâmica (limite de 400px)
    });

    setLoading(false); // Imagem carregada
    if (onImageLoad) onImageLoad(); // Notifica o carregamento
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: loading ? '#f0f0f0' : 'transparent', // Placeholder enquanto carrega
        minHeight: boxHeight, // Define a altura inicial da caixa enquanto a imagem carrega
        transition: 'min-height 0.3s ease', // Transição suave ao ajustar a altura
      }}
    >
      <img
        src={message.mediaUrl}
        alt="message"
        onLoad={handleImageLoadInternal} // Chama a função interna de carregamento
        onError={handleImageLoadInternal} // Considera o erro como carregado para evitar travamentos
        onClick={() => handleImageClick(imageIndex)}
        style={{
          width: imageSize.width,
          height: imageSize.height,
          maxWidth: '100%',
          maxHeight: '400px', // Garantir que não ultrapasse 400px
          borderRadius: '8px', // Bordas arredondadas para estética
          transition: 'all 0.3s ease', // Transição suave para largura/altura
        }}
      />
    </div>
  );
};

export default ImagePreview;
