import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxHeight: "400px",
    overflowY: "auto",
    paddingTop: 0,
    backgroundColor: theme.palette.mode === "dark" ? "#2b2b2b" : "white", // Ajusta o fundo para modo noturno
    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000", // Ajusta a cor do texto
  },
  searchField: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    backgroundColor: theme.palette.mode === "dark" ? "#333333" : "white", // Fundo do campo de busca
    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000", // Cor do texto do campo de busca
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#555555" : "#dddddd"}`, // Linha divisória no modo noturno
  },
  listEndMessage: {
    textAlign: "center",
    padding: 16,
    color: theme.palette.mode === "dark" ? "#aaaaaa" : "#666666", // Cor de texto ajustada para ambos os modos
  },
}));

const ContactListDialog = ({ open, onClose, onSelectContact }) => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const abortControllerRef = useRef(null);

  const fetchContacts = async (reset = false) => {
    if (loading) return;

    setLoading(true);

    try {
      abortControllerRef.current = new AbortController();
      const { data } = await api.get("/contacts", {
        signal: abortControllerRef.current.signal,
        params: { searchParam, pageNumber },
      });

      setContacts((prev) => (reset ? data.contacts : [...prev, ...data.contacts]));
      setHasMore(data.hasMore);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Erro ao carregar contatos:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) return;
  
    const fetchContactsWrapper = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Cancelar requisição anterior, se existir
      }
  
      abortControllerRef.current = new AbortController();
      try {
        await fetchContacts(pageNumber === 1); // Reset se pageNumber for 1
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Erro ao buscar contatos:", error);
        }
      }
    };
  
    fetchContactsWrapper();
  
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [open, searchParam, pageNumber]);
  

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    setPageNumber(1);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Selecione um contato</DialogTitle>

      <div className={classes.searchField}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Pesquisar contato..."
          value={searchParam}
          onChange={handleSearch}
        />
      </div>

      <DialogContent onScroll={handleScroll} className={classes.dialogContent}>
        <List>
          {contacts.map((contact) => (
            <ListItem
              key={contact.id}
              button
              onClick={() => onSelectContact(contact)}
            >
              <ListItemAvatar>
                <Avatar src={contact.profilePicUrl || "/default-avatar.png"}>
                  {contact.name[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={contact.name} secondary={contact.number} />
            </ListItem>
          ))}

          {loading && (
            <div style={{ textAlign: "center", padding: 16 }}>
              <CircularProgress size={24} />
            </div>
          )}

          {!hasMore && contacts.length > 0 && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.listEndMessage}
            >
              Todos os contatos foram carregados.
            </Typography>
          )}

          {!loading && contacts.length === 0 && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.listEndMessage}
            >
              Nenhum contato encontrado.
            </Typography>
          )}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactListDialog;
