import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import CloseIcon from "@material-ui/icons/Close";
import MarkdownWrapper from "../../components/MarkdownWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    maxWidth: "100%",
    borderRadius: "12px",
    backgroundColor: theme.palette.type === "dark" ? "#131c21" : "#f9f9f9",
    color: theme.palette.text.primary,
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.type === "dark" ? "#131c21" : "#f9f9f9",
    borderRadius: theme.shape.borderRadius,
  },
  searchField: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  actions: {
    textAlign: "right",
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  contactName: {
    display: "flex",
    alignItems: "center",
  },
  dialogContent: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  tableHead: {
    backgroundColor: theme.palette.type === "dark" ? "#424242" : theme.palette.primary.light,
    "& th": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
}));


const ListagemProtocolos = () => {
  const classes = useStyles();
  const [protocolos, setProtocolos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const [selectedMessages, setSelectedMessages] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = async (ticketId) => {
    try {
      const response = await api.get(`/protocolos/${ticketId}`);
      setSelectedMessages(response.data.messages || []);
      setOpenModal(true);
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMessages([]);
  };


  const fetchProtocolos = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const response = await api.get("/protocolos", {
        params: {
          pageNumber,
          pageSize: rowsPerPage,
          searchParam,
        },
      });
      const { protocols, count } = response.data;
      setProtocolos(protocols || []);
      setTotalCount(count || 0);
    } catch (error) {
      console.error("Erro ao buscar protocolos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProtocolos(page + 1);
  }, [page, rowsPerPage]);

  const handleSearch = () => {
    setPage(0);
    fetchProtocolos(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root} elevation={0}>
        <Typography variant="h5" component="h1" className={classes.title}>
          Listagem de Protocolos
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Buscar por protocolo"
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              className={classes.searchField}
            />
          </Grid>
          <Grid item xs={3} className={classes.actions}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Buscar
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress style={{ display: "block", margin: "20px auto" }} />
        ) : (
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Protocolo</TableCell>
                  <TableCell>Contato</TableCell>
                  <TableCell>Avaliação</TableCell>
                  <TableCell>DT. Inicio </TableCell>
                  <TableCell>DT. Final</TableCell>
                  <TableCell>Mensagens</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {protocolos.length > 0 ? (
                  protocolos.map((protocolo) => (
                    <TableRow key={protocolo.id}>
                      <TableCell>{protocolo.protocolo || "N/A"}</TableCell>
                      <TableCell>
                        <div className={classes.contactName}>
                          <Avatar
                            src={
                              protocolo.ticket.contact?.profilePicUrl ||
                              "https://via.placeholder.com/150"
                            }
                            alt={protocolo.ticket.contact?.name || "Sem Nome"}
                            className={classes.avatar}
                          />
                          {protocolo.ticket.contact?.name || "Sem Nome"}
                        </div>
                      </TableCell>
                      <TableCell>
                        {protocolo?.userRating?.rate || "Pendente"}
                      </TableCell>
                      <TableCell>
                        {new Date(protocolo.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {protocolo.finishedAt ? (new Date(protocolo.finishedAt).toLocaleString()) : ''}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => handleOpenModal(protocolo.protocolo)}
                        >
                          {protocolo.messageCount} mensagens
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Nenhum protocolo encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>
          Chat do Protocolo
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ maxHeight: "500px", overflowY: "auto" }}>
          {selectedMessages.length > 0 ? (
            selectedMessages.map((message) => (
              <div
                key={message.id}
                style={{
                  display: "flex",
                  justifyContent: message.fromMe ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    backgroundColor: message.fromMe ? "#e6f7ff" : "#f1f1f1",
                    padding: "10px",
                    borderRadius: "10px",
                    maxWidth: "70%",
                  }}
                >
                  {message.mediaType === "image" ? (
                    <div>
                      <img
                        src={message.mediaUrl}
                        alt="Preview da mensagem"
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                      />
                      <Typography
                        variant="caption"
                        style={{
                          display: "block",
                          textAlign: "center",
                          color: "#555",
                        }}
                      >
                        Imagem enviada
                      </Typography>
                    </div>
                  ) : (
                    <Typography
                      variant="body2"
                      style={{
                        color: message.fromMe ? "#00529B" : "#333",
                      }}
                    >
                      <MarkdownWrapper>
                        {message.body || "Mensagem sem conteúdo"}
                      </MarkdownWrapper>
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    style={{
                      display: "block",
                      marginTop: "5px",
                      textAlign: "right",
                      color: message.fromMe ? "#00529B" : "#333",
                    }}
                  >
                    {new Date(message.createdAt).toLocaleString()}
                  </Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography>Nenhuma mensagem encontrada.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>



    </Container>
  );
};

export default ListagemProtocolos;
