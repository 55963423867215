import React, { useEffect, useState, useContext } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";

import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  useTheme,
  Grid,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { SocketContext } from "../../context/Socket/SocketContext";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(2),
  },
  qrCodeContainer: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  qrCode: {
    marginTop: theme.spacing(2),
  },
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const [qrCode, setQrCode] = useState("");
  const theme = useTheme();
  const classes = useStyles();

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose, socketManager]);

  const steps = [
    "Abra o WhatsApp no seu celular",
    "Toque em Mais opções no Android ou em Configurações no iPhone",
    "Toque em Dispositivos conectados e, em seguida, em Conectar dispositivos",
    "Aponte seu celular para esta tela para capturar o QR Code",
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" scroll="paper">
      <DialogTitle>
        <Typography variant="h6" align="center">
          Conecte-se ao WhatsApp
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogPaper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stepper orientation="vertical" className={classes.instructions}>
              {steps.map((label, index) => (
                <Step key={index} active={true}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.qrCodeContainer}>
            {qrCode ? (
              <QRCode value={qrCode} size={256} className={classes.qrCode} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
