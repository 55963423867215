import React, { useState, useEffect } from "react";
import { makeStyles, TextField, Grid, Container } from "@material-ui/core";
import { Formik, Form, FastField, FieldArray } from "formik";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    { weekday: "Segunda-feira", weekdayEn: "monday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Terça-feira", weekdayEn: "tuesday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Quarta-feira", weekdayEn: "wednesday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Quinta-feira", weekdayEn: "thursday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Sexta-feira", weekdayEn: "friday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Sábado", weekdayEn: "saturday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
    { weekday: "Domingo", weekdayEn: "sunday", startTime: "", endTime: "", lunchStart: "", lunchEnd: "", },
  ]);

  useEffect(() => {
    if (isArray(initialValues) && initialValues.length > 0) {
      setSchedules(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={{ schedules }}
      onSubmit={({ schedules }) =>
        setTimeout(() => {
          handleSubmit(schedules);
        }, 500)
      }
    >
      {({ values }) => (
       <Form className={classes.fullWidth}>
       <FieldArray
         name="schedules"
         render={(arrayHelpers) => (
           <Grid spacing={4} container>
             {values.schedules.map((item, index) => (
               <Grid container key={index} direction="row" alignItems="center" spacing={2}>
                 <Grid item xs={2}>
                   <FastField
                     as={TextField}
                     label="Dia da Semana"
                     name={`schedules[${index}].weekday`}
                     disabled
                     variant="outlined"
                     fullWidth
                     margin="dense"
                   />
                 </Grid>
                 <Grid item xs={2}>
                   <FastField name={`schedules[${index}].startTime`}>
                     {({ field }) => (
                       <NumberFormat
                         label="Hora de Início"
                         {...field}
                         variant="outlined"
                         margin="dense"
                         customInput={TextField}
                         format="##:##"
                         fullWidth
                       />
                     )}
                   </FastField>
                 </Grid>
                 <Grid item xs={2}>
                   <FastField name={`schedules[${index}].lunchStart`}>
                     {({ field }) => (
                       <NumberFormat
                         label="Início do Almoço"
                         {...field}
                         variant="outlined"
                         margin="dense"
                         customInput={TextField}
                         format="##:##"
                         fullWidth
                       />
                     )}
                   </FastField>
                 </Grid>
                 <Grid item xs={2}>
                   <FastField name={`schedules[${index}].lunchEnd`}>
                     {({ field }) => (
                       <NumberFormat
                         label="Fim do Almoço"
                         {...field}
                         variant="outlined"
                         margin="dense"
                         customInput={TextField}
                         format="##:##"
                         fullWidth
                       />
                     )}
                   </FastField>
                 </Grid>
                 <Grid item xs={2}>
                   <FastField name={`schedules[${index}].endTime`}>
                     {({ field }) => (
                       <NumberFormat
                         label="Hora de Fim"
                         {...field}
                         variant="outlined"
                         margin="dense"
                         customInput={TextField}
                         format="##:##"
                         fullWidth
                       />
                     )}
                   </FastField>
                 </Grid>
                
               </Grid>
             ))}
           </Grid>
         )}
       ></FieldArray>
       <div
         style={{ textAlign: "center", marginTop: "2%" }}
         className={classes.buttonContainer}
       >
         <ButtonWithSpinner
           loading={loading}
           type="submit"
           color="primary"
           variant="contained"
         >
           {labelSaveButton ?? "Salvar"}
         </ButtonWithSpinner>
       </div>
     </Form>
     

      )}
    </Formik>
  );
}

export default SchedulesForm;
