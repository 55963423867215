import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(0),
    height: '95vh',
    overflowX: "auto", // Habilita o scroll horizontal
    overflowY: "hidden", // Desabilita o scroll vertical
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  profileImage: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    marginRight: "10px"
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
  },
  contactInfo: {
    marginLeft: "10px",
    fontWeight: "bold",
  },
  board: {
    maxHeight: '100%', // Garante que o board ocupe a altura total da área visível
    overflowX: "auto", // Habilita scroll horizontal dentro do board
    overflowY: "hidden", // Desabilita o scroll vertical
  }
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tags, setTags] = useState([]);
  const [file, setFile] = useState({
    lanes: []
  });
  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      setTags(response.data.lista || []);
      await fetchTickets(jsonString); // Chama fetchTickets após carregar tags
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Em aberto"),
        label: "0",
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: `Ticket nº ${ticket.id.toString()}`,
          description: (
            <div className={classes.cardContent}>
              <img
                src={ticket.contact.profilePicUrl || "https://app.rodticket.com/nopicture.jpg"}
                alt="Profile"
                className={classes.profileImage}
              />
              <div className={classes.contactInfo}>
                <p>{ticket.contact.name || ticket.contact.number}</p>
                <p>{ticket.lastMessage}</p>
                <button
                  className={classes.button}
                  onClick={() => handleCardClick(ticket.uuid)}
                >
                  Ver Ticket
                </button>
              </div>
            </div>
          ),
          title: ticket.contact.name || ticket.contact.number,
          draggable: true,
          href: `/tickets/${ticket.uuid}`,
        })),
      },
      ...tags.map(tag => {
        const filteredTicketsByTag = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          label: tag.id.toString(),
          cards: filteredTicketsByTag.map(ticket => ({
            id: ticket.id.toString(),
            label: `Ticket nº ${ticket.id.toString()}`,
            description: (
              <div className={classes.cardContent}>
                <img
                  src={ticket.contact.profilePicUrl || "https://app.rodticket.com/nopicture.png"}
                  alt="Profile"
                  className={classes.profileImage}
                />
                <div className={classes.contactInfo}>
                  <p>{ticket.contact.name || ticket.contact.number}</p>
                  <p>{ticket.lastMessage}</p>
                  <button
                    className={classes.button}
                    onClick={() => handleCardClick(ticket.uuid)}
                  >
                    Ver Ticket
                  </button>
                </div>
              </div>
            ),
            title: ticket.contact.name || ticket.contact.number,
            draggable: true,
            href: `/tickets/${ticket.uuid}`,
          })),
          style: { backgroundColor: tag.color, color: "white" },
        };
      })
    ];

    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    history.push(`/tickets/${uuid}`);
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, tickets]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
     // toast.success('Ticket Tag Removido!');
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      toast.success('Adicionado com Sucesso!');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        className={classes.board}
        style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
      />
    </div>
  );
};

export default Kanban;
