import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Chip, TextField, IconButton, Grid } from '@mui/material';
import { styled } from '@mui/system';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentIcon from '@mui/icons-material/Comment';

const DialogWrapper = styled(DialogContent)({
  backgroundColor: '#ffffff',
  color: '#000000',
  padding: '20px',
  width: '100%',
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  color: '#000000',
  marginBottom: '10px',
});

const TagContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
});

const AnexoItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: '#f0f0f0',
  padding: '10px',
  borderRadius: '5px',
  marginBottom: '10px',
  color: '#007BFF',
  cursor: 'pointer',
});

const ComentarioItem = styled('div')({
  marginBottom: '10px',
  padding: '10px',
  backgroundColor: '#f0f0f0',
  borderRadius: '5px',
});

const ContentContainer = styled('div')({
  maxHeight: '60vh', // Altura fixa para o conteúdo da tarefa
  overflowY: 'auto', // Adiciona scroll interno se o conteúdo ultrapassar a altura
  paddingRight: '10px', // Adiciona espaçamento interno para evitar o corte do conteúdo próximo ao scroll
});

const TarefaDialog = ({ open, onClose, tarefaDetalhes }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true} maxWidth="lg">
      <DialogWrapper>
        <Typography variant="h5" gutterBottom style={{ color: '#000000', fontWeight: 'bold' }}>
          {tarefaDetalhes.titulo}
        </Typography>
        <Typography variant="body2" style={{ color: '#666666' }}>
          na lista {tarefaDetalhes.etapa?.nome}
        </Typography>

        <Grid container spacing={4}>
          {/* Coluna Esquerda - Descrição e Comentários */}
          <Grid item xs={12} md={10} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <ContentContainer>
              <SectionTitle variant="body1">Descrição</SectionTitle>
              <div dangerouslySetInnerHTML={{ __html: tarefaDetalhes.descricao }} style={{ color: '#666666' }} />
            </ContentContainer>

            {/* Área de Comentário - Sempre visível na parte inferior */}
            <div>
              <SectionTitle variant="body1">Comentários</SectionTitle>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  placeholder="Escrever um comentário..."
                  variant="outlined"
                  style={{ backgroundColor: '#f0f0f0', borderRadius: '5px', color: '#000000' }}
                  InputProps={{
                    style: { color: '#000000' }
                  }}
                />
                <IconButton style={{ color: '#007BFF', marginLeft: '10px' }}>
                  <CommentIcon />
                </IconButton>
              </div>
              {tarefaDetalhes.comentarios.map(comentario => (
                <ComentarioItem key={comentario.id}>
                  <Typography variant="body2" style={{ color: '#666666' }}>
                    {comentario.conteudo}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom style={{ marginTop: '5px', color: '#999999' }}>
                    {new Date(comentario.createdAt).toLocaleString()}
                  </Typography>
                </ComentarioItem>
              ))}
            </div>
          </Grid>

          {/* Coluna Direita - Tags e Anexos */}
          <Grid item xs={12} md={2} sx={{ backgroundColor: "rgb(227, 227, 227)" }}>
            <div>
              <SectionTitle variant="body1">Tags</SectionTitle>
              <TagContainer>
                {tarefaDetalhes.tags.length > 0 ? (
                  tarefaDetalhes.tags.map(tag => (
                    <Chip key={tag.id} label={tag.nome} style={{ backgroundColor: '#e0e0e0', color: '#000000' }} />
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">Sem tags</Typography>
                )}
              </TagContainer>
            </div>

            <div style={{ marginTop: '20px' }}>
              <SectionTitle variant="body1">Anexos</SectionTitle>
              {tarefaDetalhes.anexos.length > 0 ? (
                tarefaDetalhes.anexos.map(anexo => (
                  <AnexoItem key={anexo.id}>
                    <AttachFileIcon style={{ color: '#007BFF' }} />
                    <a href={anexo.url} target="_blank" rel="noopener noreferrer" style={{ color: '#007BFF' }}>
                      {anexo.nomeArquivo}
                    </a>
                  </AnexoItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">Sem anexos</Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogWrapper>
      <DialogActions style={{ backgroundColor: '#ffffff' }}>
        <Button onClick={onClose} style={{ color: '#007BFF' }}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TarefaDialog;
