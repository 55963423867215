import React, { useState } from "react";
import { Modal, Typography, Button } from "@material-ui/core";

const ErrorModal = ({ open, onClose, message }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        backgroundColor: 'white', padding: '16px', borderRadius: '8px'
      }}>
        <Typography variant="h6">Erro</Typography>
        <Typography variant="body1">{message}</Typography>
        <Button onClick={onClose} color="primary" variant="contained">
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(ErrorModal);
