import React, { useState, useEffect, useContext } from "react";
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Avatar,
    Grid,
    Select,
    IconButton,
} from "@material-ui/core";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import ParticipantsTable from "./ParticipantsTable";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        maxWidth: "100%",
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    },
    infoBox: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 3px 10px rgba(0,0,0,0.15)",
        backgroundColor: theme.palette.background.paper,
    },
    infoTitle: {
        fontSize: "0.875rem",
        fontWeight: 600,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(0.5),
    },
    infoValue: {
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    tableContainer: {
        marginTop: theme.spacing(4),
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    },
    tableHead: {
        backgroundColor: theme.palette.primary.light,
        "& th": {
            color: theme.palette.primary.contrastText,
            fontWeight: "bold",
        },
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
    },
    addParticipantButton: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
        marginBottom: theme.spacing(2),
    },
    backGroups: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: theme.palette.warning.dark,
        },
        marginBottom: theme.spacing(2),
    }
}));

const filter = createFilterOptions({
    trim: true,
});


const DetalhesGrupo = () => {
    const classes = useStyles();
    const { groupId } = useParams(); // Obter o ID do grupo da URL
    const [groupDetails, setGroupDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [newParticipant, setNewParticipant] = useState("");
    const { user } = useContext(AuthContext);
    const { companyId, whatsappId } = user;

    const [options, setOptions] = useState([]);
    const [loadingSearchContato, setLoadingSearchContato] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [newContact, setNewContact] = useState({});
    const [groupImage, setGroupImage] = useState(null);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");

    const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
    const [newDescription, setNewDescription] = useState("");

    useEffect(() => {
        if (!openAddDialog || searchParam.length < 3) {
            setLoadingSearchContato(false);
            return;
        }
        setLoadingSearchContato(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });

                    console.log({ setando: data.contacts });

                    // Mapear contatos para garantir formato correto
                    const formattedContacts = data.contacts.map((contact) => ({
                        id: contact.id || contact.number, // Usar `id` ou `number` como fallback
                        name: contact.name || "Sem Nome", // Fallback para `name`
                        number: contact.number || "Sem Número", // Fallback para `number`
                    }));
                    setOptions(formattedContacts);
                    setLoadingSearchContato(false);
                } catch (err) {
                    setLoadingSearchContato(false);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, openAddDialog]);

    useEffect(() => {
        console.log({ options });

    }, [options])

    useEffect(() => {
        console.log({ groupDetails });

        //groupDetails.imageUrl

        if (groupDetails && groupDetails.imageUrl) {
            setGroupImage(groupDetails.imageUrl);
        }

    }, [groupDetails]);


    // Função para buscar detalhes do grupo
    const fetchGroupDetails = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/group/${groupId}`);
            setGroupDetails(response.data.data);
            setError(null);
        } catch (error) {
            console.error("Erro ao buscar detalhes do grupo:", error);
            setError("Erro ao carregar os detalhes do grupo.");
        } finally {
            setLoading(false);
        }
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            //setContactModalOpen(true);
        }
    };


    const handleOpenEditDialog = () => {
        setNewGroupName(groupDetails.name);
        setOpenEditDialog(true);
    };

    const handleEditGroupName = async () => {
        try {
            await api.put(`/group/${groupId}/edit-name`, { newName: newGroupName });
            toast.success("Nome do grupo atualizado com sucesso!");
            setOpenEditDialog(false);
            fetchGroupDetails(); // Atualiza os detalhes do grupo
        } catch (error) {
            console.error("Erro ao atualizar nome do grupo:", error);
            toast.error("Erro ao atualizar o nome do grupo.");
        }
    };

    const handleOpenEditDescriptionDialog = () => {
        setNewDescription(groupDetails.description);
        setOpenDescriptionDialog(true);
    };

    const handleEditGroupDescription = async () => {
        try {
            await api.put(`/group/${groupId}/edit-description`, { newDescription: newDescription });
            toast.success("Descricao do grupo atualizado com sucesso!");
            setOpenDescriptionDialog(false);
            fetchGroupDetails(); // Atualiza os detalhes do grupo
        } catch (error) {
            console.error("Erro ao atualizar nome do grupo:", error);
            toast.error("Erro ao atualizar o nome do grupo.");
        }
    };



    // Adicionar participante
    const handleAddParticipant = async () => {
        if (!selectedContact) return;

        try {
            await api.post(`/group/${groupId}/add-participant`, {
                participantId: selectedContact.number + "@s.whatsapp.net",
            });
            //alert("Participante adicionado com sucesso!");
            toast.success("Participante adicionado com sucesso!", {
                toastId: "participantAdded",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setOpenAddDialog(false);
            setSelectedContact(null);
            fetchGroupDetails(); // Atualizar lista de participantes
        } catch (error) {
            console.error("Erro ao adicionar participante:", error);
            const errorMsg = error.response?.data?.message || "Erro ao adicionar participante.";
            toast.error(errorMsg, {
                toastId: "addParticipantError",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };


    // Remover participante
    const handleRemoveParticipant = async (participantId) => {
        if (!window.confirm("Deseja remover este participante?")) return;

        try {
            // Use DELETE para ações de remoção, seguindo boas práticas REST
            await api.delete(`/group/${groupId}/remove-participant`, {
                data: { participantId }, // Use o campo `data` para passar o corpo da requisição no DELETE
            });

            toast.success("Participante removido com sucesso!", {
                toastId: "participantRemoved",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            fetchGroupDetails(); // Atualizar lista de participantes
        } catch (error) {
            console.error("Erro ao remover participante:", error);

            const errorMsg =
                error.response?.data?.message || "Erro ao remover participante.";

            toast.error(errorMsg, {
                toastId: "removeParticipantError",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };



    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);
        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }
        return filtered;
    };


    const renderContactAutocomplete = () => {

        return (
            <Grid xs={12} item>
                <Autocomplete
                    fullWidth
                    options={options}
                    loading={loadingSearchContato}
                    clearOnBlur
                    autoHighlight
                    freeSolo
                    clearOnEscape
                    getOptionLabel={renderOptionLabel}
                    renderOption={(option, test) => {
                        console.log("Opção sendo renderizada:", option, test); // Adiciona o log aqui
                        return (
                            <div key={option.id}>
                                <strong>{option.name || "Sem Nome"}</strong> - {option.number || "Sem Número"}
                            </div>
                        );
                    }}


                    filterOptions={createAddContactOption}
                    onChange={(e, newValue) => handleSelectOption(e, newValue)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={i18n.t("newTicketModal.fieldLabel")}
                            variant="outlined"
                            autoFocus
                            onChange={e => setSearchParam(e.target.value)}
                            onKeyPress={e => {
                                if (loading || !selectedContact) return;
                                else if (e.key === "Enter") {
                                    // handleSaveTicket(selectedContact.id);
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        )

        return null;
    }



    useEffect(() => {
        fetchGroupDetails();
    }, []);

    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.root}>
                <Typography variant="h6">{error}</Typography>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", gap: "10px" }}>
                <Typography variant="h4" gutterBottom>
                    Detalhes do Grupo
                </Typography>

                <div style={{ display: "flex", gap: "10px" }}>
                    {/* Botão de Voltar */}
                    <Button
                        variant="contained"
                        className={classes.backGroups}
                        onClick={() => (window.location.href = "/grupos")}
                    >
                        Voltar para Grupos
                    </Button>

                    {/* Botão de Adicionar Participante */}
                    {
                        groupDetails.isAdmin && (
                            <Button
                                variant="contained"
                                className={classes.addParticipantButton}
                                onClick={() => setOpenAddDialog(true)}
                            >
                                Adicionar Participante
                            </Button>
                        )
                    }
                </div>
            </div>


            <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                {/* Foto do Grupo */}
                <Grid item xs={12} md={3}>
                    <Paper className={classes.infoBox}>
                        <Typography className={classes.infoTitle}>Foto do Grupo</Typography>
                        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                            {groupImage ? (
                                <img
                                    src={groupImage}
                                    alt="Foto do Grupo"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "8px",
                                        marginTop: "10px",
                                    }}
                                />
                            ) : (
                                <Typography className={classes.infoValue}>
                                    Nenhuma imagem disponível
                                </Typography>
                            )}


                        </div>
                    </Paper>

                </Grid>


                {/* Detalhes do Grupo */}
                <Grid item xs={12} md={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.infoBox}>
                                <Typography className={classes.infoTitle}>Nome do Grupo</Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className={classes.infoValue} style={{ flex: 1 }}>
                                        {groupDetails.name}
                                    </Typography>
                                    <IconButton
                                        aria-label="Editar Nome do Grupo"
                                        onClick={handleOpenEditDialog} // Função para abrir o modal de edição
                                        color="primary"
                                        size="small"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper className={classes.infoBox}>
                                <Typography className={classes.infoTitle}>Descrição do Grupo</Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className={classes.infoValue} style={{ flex: 1 }}>
                                        {groupDetails.description}
                                    </Typography>
                                    <IconButton
                                        aria-label="Editar Nome do Grupo"
                                        onClick={handleOpenEditDescriptionDialog} // Função para abrir o modal de edição
                                        color="primary"
                                        size="small"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper className={classes.infoBox}>
                                <Typography className={classes.infoTitle}>Data de Criação</Typography>
                                <Typography className={classes.infoValue}>
                                    {groupDetails.createdAt
                                        ? new Date(groupDetails.createdAt * 1000).toLocaleDateString()
                                        : "N/A"}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.infoBox}>
                                <Typography className={classes.infoTitle}>Dono do Grupo</Typography>
                                <Typography className={classes.infoValue}>{groupDetails.owner}</Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} >
                            <ParticipantsTable
                                groupDetails={groupDetails}
                                handleRemoveParticipant={handleRemoveParticipant}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>



            {/* Tabela de Participantes */}


            <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("newTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid style={{ width: 300 }} container spacing={2}>
                        {/* CONTATO */}
                        {renderContactAutocomplete()}

                        {/* CONEXAO */}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { setOpenAddDialog(false) }}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        Cancelar
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact}
                        onClick={() => handleAddParticipant(selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        Adicionar
                    </ButtonWithSpinner>
                </DialogActions>

            </Dialog >

            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Editar Nome do Grupo</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Novo Nome do Grupo"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenEditDialog(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleEditGroupName}
                        color="primary"
                        variant="contained"
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDescriptionDialog} onClose={() => setOpenDescriptionDialog(false)}>
                <DialogTitle>Editar Descricao do Grupo</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Nova Descricao do Grupo"
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDescriptionDialog(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleEditGroupDescription}
                        color="primary"
                        variant="contained"
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>


        </div>

    );

};

export default DetalhesGrupo;
