import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import { AttachFile, DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ModuloSchema = Yup.object().shape({
  nome: Yup.string().min(2, "Nome muito curto!").max(50, "Nome muito longo!").required("Nome é obrigatório")
});

const ModuloModal = ({ open, onClose, moduloId }) => {
  const classes = useStyles();

  const initialState = {
    nome: "",
    filas: [],
    whatsapps_ids: [],
    companyId: "",
  };

  const [modulo, setModulo] = useState(initialState);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!moduloId) return;
      try {
        const { data } = await api.get(`/modulos/${moduloId}`);
        setModulo((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setModulo(initialState);
    };
  }, [moduloId, open]);

  const handleClose = () => {
    onClose();
    setModulo(initialState);
  };


  const handleSaveModulo = async (values) => {
    try {
      if (moduloId) {
        await api.put(`/modulos/${moduloId}`, {
          ...values,
        });

      } else {
        const { data } = await api.post("/modulos", {
          ...values,
        });

      }
      toast.success("Módulo salvo com sucesso");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };


  return (
    <div className={classes.root}>

      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {moduloId
            ? `Editar`
            : `Adicionar`}
        </DialogTitle>
        <Paper>
          <Formik
            initialValues={modulo}
            enableReinitialize={true}
            validationSchema={ModuloSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveModulo(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <Form>
                <DialogContent dividers>
                  <Field
                    as={TextField}
                    label={`Nome`}
                    fullWidth
                    autoFocus
                    name="nome"
                    error={touched.nome && Boolean(errors.nome)}
                    helperText={touched.nome && errors.nome}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />

                </DialogContent>
                <DialogActions>

                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {moduloId
                      ? `Editar`
                      : `Salvar`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Paper>
      </Dialog>
    </div>
  );
};

export default ModuloModal;
