import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay, TransferWithinAStationRounded } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";
import ModuloSelectDialog from "../TicketListItemCustom/ModuloSelectDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { toast } from "react-toastify";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ScheduleModal from "../ScheduleModal";

const useStyles = makeStyles((theme) => ({
	actionButtons: {
		display: "flex", // Usa flexbox para alinhar os ícones em linha
		flexWrap: "nowrap", // Evita quebra de linha
		justifyContent: "flex-end", // Alinha os ícones à direita
		alignItems: "center", // Centraliza os ícones verticalmente
		marginRight: 6,
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5), // Espaçamento entre os ícones
		},
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-between", // Ajusta alinhamento para telas pequenas
			flexWrap: "wrap", // Permite quebra em telas menores (caso necessário)
			padding: theme.spacing(1), // Adiciona espaçamento interno
		},
	},
	iconContainer: {
		display: "flex",
		overflowX: "auto", // Habilita a rolagem horizontal
		gap: theme.spacing(1), // Espaçamento entre os ícones
		alignItems: "center",
		whiteSpace: "nowrap", // Impede quebra de linha
	},
	iconButton: {
		maxWidth: "40px", // Define uma largura máxima para os botões
		maxHeight: "40px", // Define uma altura máxima para os botões
		[theme.breakpoints.down("sm")]: {
			maxWidth: "30px",
			maxHeight: "30px",
		},
	},
}));


const TicketActionButtonsCustom = ({ ticket }) => {

	console.log({ caraiii: ticket });


	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [openModuleSelect, setOpenModuleSelect] = useState(false); // Estado para abrir o diálogo de seleção de módulo
	const [selectedStatus, setSelectedStatus] = useState(null); // Estado para armazenar o status de atualização
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);
	const [selectedModule, setSelectedModule] = useState(null);  // Estado para armazenar o módulo selecionado
	const ticketOptionsMenuOpen = Boolean(anchorEl); // Define se o menu está aberto com base no valor de anchorEl
	const [transferModalOpen, setTransferModalOpen] = useState(false); // Estado do modal

	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [contactId, setContactId] = useState(null);

	const customTheme = createTheme({
		palette: {
			primary: green,
		},
	});

	const handleOpenTicketOptionsMenu = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = () => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = (e, status, userId) => {
		if (status === "closed") {
			setOpenModuleSelect(true); // Abre o diálogo para selecionar o módulo
			setSelectedStatus({ status, userId }); // Armazena o status e o userId temporariamente
		} else {
			updateTicketStatus(status, userId); // Atualiza o ticket diretamente para outros status
		}
	};

	// Função auxiliar para atualizar o ticket com ou sem módulo
	const updateTicketStatus = async (status, userId, moduleId = null) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status,
				userId: userId || null,
				moduleId, // Inclui o moduleId ao fechar o ticket
				useIntegration: status === "closed" ? false : ticket.useIntegration,
				promptId: status === "closed" ? false : ticket.promptId,
				integrationId: status === "closed" ? false : ticket.integrationId,
			});

			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null });
				history.push("/tickets");
			}
		} catch (err) {
			toastError(err);
		} finally {
			setLoading(false);
		}
	};

	// Função chamada após o módulo ser selecionado no diálogo
	const handleSubmitModule = (moduleId) => {
		updateTicketStatus(selectedStatus.status, selectedStatus.userId, moduleId);
		setOpenModuleSelect(false); // Fecha o diálogo
	};

	const handleOpenTransferModal = () => {
		setTransferModalOpen(true);
	};

	const handleCloseTransferModal = () => {
		setTransferModalOpen(false);
	};

	const handleOpenScheduleModal = () => {
		setContactId(ticket.contact.id);
		setScheduleModalOpen(true);
	}

	const handleCloseScheduleModal = () => {
		setScheduleModalOpen(false);
		setContactId(null);
	}


	return (
		<div className={classes.actionButtons}>
			<ModuloSelectDialog
				openModuleSelect={openModuleSelect}
				handleCloseModuleSelect={() => setOpenModuleSelect(false)}
				handleSubmitModule={handleSubmitModule}
			/>

			<TransferTicketModalCustom
				modalOpen={transferModalOpen}
				onClose={handleCloseTransferModal}
				ticketid={ticket.id}
			/>

			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>

					<div className={classes.iconContainer}>
						<Tooltip title="Agendar Mensagem">
							<IconButton onClick={handleOpenScheduleModal}>
								<ScheduleIcon color="secondary" />
							</IconButton>
						</Tooltip>

						<Tooltip title="Solicitar avaliação para este ticket">
							<IconButton
								onClick={async () => {
									toast.success("Solicitação de avaliação enviada com sucesso!");
									await api.post(`/ticket/${ticket?.id}/sendRating`);
								}}
							>
								<FeedbackIcon color="secondary" />
							</IconButton>
						</Tooltip>

						<Tooltip title={i18n.t("ticketsList.buttons.transfer")}>
							<IconButton className={classes.iconButton} onClick={handleOpenTransferModal}>
								<TransferWithinAStationRounded style={{ color: '#3B82F6' }} />
							</IconButton>
						</Tooltip>

						<ThemeProvider theme={customTheme}>
							<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
								<IconButton onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)} color="primary">
									<CheckCircleIcon />
								</IconButton>
							</Tooltip>
						</ThemeProvider>

						<IconButton onClick={handleOpenTicketOptionsMenu}>
							<MoreVert />
						</IconButton>
					</div>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>


				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}

			<ScheduleModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				aria-labelledby="form-dialog-title"
				contactId={contactId}
			/>
		</div>
	);
};

export default TicketActionButtonsCustom;
