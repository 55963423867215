import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import './button.css';


const useStyles = makeStyles((theme) => ({
    chart: {
      backgroundColor: theme.palette.mode === "dark" ? "#424242" : "#FFFFFF", // Fundo escuro no modo dark e branco no normal
      color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Texto cinza claro no dark e preto no normal
      borderRadius: "8px", // Bordas arredondadas para um design mais moderno
      padding: theme.spacing(2), // Espaçamento interno consistente com o tema
    },
    gridLines: {
      color: theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.1)" // Linhas da grade suaves no modo dark
        : "rgba(0, 0, 0, 0.1)", // Linhas da grade suaves no modo claro
    },
    ticks: {
      color: theme.palette.mode === "dark" ? "#E0E0E0" : "#424242", // Cor dos ticks ajustada para cada tema
      fontSize: "12px", // Ajuste do tamanho do texto dos ticks
      fontWeight: 500, // Consistência no peso do texto
    },
    title: {
      color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121", // Título mais destacado para cada modo
      fontWeight: "bold", // Destaca o título
      fontSize: "16px", // Tamanho do título para maior visibilidade
    },
    legendLabels: {
      color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121", // Cor das legendas ajustadas para cada modo
      fontSize: "12px",
    },
  }));
  

  
  

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);


export const getChartOptions = (theme) => ({
    responsive: true,
    plugins: {
        legend: {
            position: "top",
            display: false,
            labels: {
                color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Cor adaptada ao modo
            },
        },
        title: {
            display: true,
            text: "Gráfico de Conversas",
            position: "left",
            color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Título com cor dinâmica
            font: {
                size: 16,
                weight: "bold",
            },
        },
        datalabels: {
            display: true,
            anchor: "start",
            offset: -30,
            align: "start",
            color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Texto adaptado ao modo
            textStrokeColor: theme.palette.mode === "dark" ? "#131c21" : "#FFFFFF", // Contorno ajustado
            textStrokeWidth: 2,
            font: {
                size: 20,
                weight: "bold",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Eixo X adaptado
            },
            grid: {
                color: theme.palette.mode === "dark" ? "#E0E0E0" : "#ccc", // Linhas do grid no eixo X
            },
        },
        y: {
            ticks: {
                color: theme.palette.mode === "dark" ? "#E0E0E0" : "#212121", // Eixo Y adaptado
            },
            grid: {
                color: theme.palette.mode === "dark" ? "#E0E0E0" : "#ccc", // Linhas do grid no eixo Y
            },
        },
    },
});


export const ChatsUser = () => {
    // const classes = useStyles();
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [] });
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const classes = useStyles();

  
 

    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        handleGetTicketsInformation();
    }, []);


    const dataCharts = {

        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.nome),
        datasets: [
            {
                data: ticketsData?.data.length > 0 && ticketsData?.data.map((item, index) => {
                    return item.quantidade
                }),
                backgroundColor: '#2DDD7F',
            },

        ],
    };

    const handleGetTicketsInformation = async () => {
        try {

            const { data } = await api.get(`/dashboard/ticketsUsers?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}&companyId=${companyId}`);
            
            setTicketsData(data);
        } catch (error) {
            toast.error('Erro ao obter informações da conversa');
        }
    }

    return (
        <div className={classes.chart}>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total de Conversas por Usuários
            </Typography>

            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} >

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => { setInitialDate(newValue) }}
                        label="Inicio"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch', color: "#fff" }} />}

                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => { setFinalDate(newValue) }}
                        label="Fim"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <Button className="buttonHover" onClick={handleGetTicketsInformation} variant='contained'>Filtrar</Button>

            </Stack>
            <Bar options={getChartOptions(theme)} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }}  />
        </div>
    );
}