import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    tableContainer: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.light,
        "& th": {
            color: theme.palette.primary.contrastText,
            fontWeight: "bold",
        },
    },
    tableCellHeader: {
        fontWeight: "bold",
        fontSize: "14px",
        color: theme.palette.common.white,
        textAlign: "center",
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    tableCell: {
        fontSize: "16px",
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        textAlign: "center",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },

    root: {
        padding: theme.spacing(4),
        maxWidth: "100%",
        borderRadius: "12px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
}));

const ListPatchNotes = () => {
    const classes = useStyles();
    const history = useHistory();

    const [patchNotes, setPatchNotes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPatchNotes();
    }, []);

    const fetchPatchNotes = async () => {
        setLoading(true);
        try {
            const { data } = await api.get("/patch-notes");
            setPatchNotes(data.data);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            
            <div className={classes.header}>
                <Typography variant="h4" align="center" gutterBottom>
                    Patch Notes
                </Typography>

        
                    <Button
                              variant="contained"
                             className={classes.addButton}
                        onClick={() => history.push("/patch-notes/create")}
                    >
                        Criar Nova Patch Note
                    </Button>
       
            </div>

            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.tableCellHeader}>Versão</TableCell>
                                <TableCell className={classes.tableCellHeader}>Data de Lançamento</TableCell>
                                <TableCell className={classes.tableCellHeader}>Descrição</TableCell>
                                <TableCell className={classes.tableCellHeader}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {patchNotes.map((patchNote) => (
                                <TableRow key={patchNote.id} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>{patchNote.version}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {new Date(patchNote.releaseDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>{patchNote.description}</TableCell>
                                    <TableCell className={classes.tableCell}>{patchNote.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default ListPatchNotes;