import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress, Grid, TextField, Typography, Divider } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import axios from "axios";
import usePlans from "../../hooks/usePlans";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: "8px",
    backgroundColor: theme.palette.type === "dark" ? "#424242" : "#f4f6f8",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  apiDetails: {
    backgroundColor: theme.palette.type === "dark" ? "#303030" : "#fff",
    padding: theme.spacing(2),
    borderRadius: "8px",
    marginBottom: theme.spacing(4),
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  endpointInfo: {
    marginBottom: theme.spacing(1),
  },
  codeBlock: {
    backgroundColor: theme.palette.type === "dark" ? "#424242" : "#e0e0e0",
    padding: theme.spacing(2),
    borderRadius: "4px",
    fontFamily: "monospace",
    whiteSpace: "pre-wrap",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
  },
  formContainer: {
    maxWidth: 500,
    marginTop: theme.spacing(2),
  },
  btnWrapper: {
    marginTop: theme.spacing(2),
  },
  textRight: {
    textAlign: "right",
  },
}));

const MessagesAPI = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [file, setFile] = useState({});
  const { getPlanCompany } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useExternalApi) {
        toast.error("Esta empresa não possui permissão para acessar essa página! Redirecionando...");
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
  }, [history, getPlanCompany]);

  const getEndpoint = () => {
    return `${process.env.REACT_APP_BACKEND_URL}/api/messages/send`;
  };

  const handleSendTextMessage = async (values) => {
    const { number, body } = values;
    const data = { number, body };
    try {
      await axios.request({
        url: getEndpoint(),
        method: "POST",
        data,
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${values.token}`, // Esclarecer o uso do token
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const handleSendMediaMessage = async (values) => {
    try {
      const firstFile = file[0];
      const data = new FormData();
      data.append("number", values.number);
      data.append("body", firstFile.name);
      data.append("medias", firstFile);
      await axios.request({
        url: getEndpoint(),
        method: "POST",
        data,
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Token ${values.token}`, // Esclarecer o uso do token
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const renderFormMessageText = () => (
    <Formik
      initialValues={{ token: "", number: "", body: "" }}
      onSubmit={(values, actions) => {
        setTimeout(async () => {
          await handleSendTextMessage(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Token gerado nas conexões"
                name="token"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Número (com código de país)"
                name="number"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Mensagem de Texto"
                name="body"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  const renderFormMessageMedia = () => (
    <Formik
      initialValues={{ token: "", number: "" }}
      onSubmit={(values, actions) => {
        setTimeout(async () => {
          await handleSendMediaMessage(values);
          actions.setSubmitting(false);
          actions.resetForm();
          setFile({});
        }, 400);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Token gerado nas conexões"
                name="token"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Número (com código de país)"
                name="number"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                name="medias"
                required
                onChange={(e) => setFile(e.target.files)}
              />
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <Paper className={classes.mainPaper}>
      <Typography variant="h5" className={classes.sectionTitle}>
        Documentação da API de Envio de Mensagens
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.sectionTitle}>
            1. Envio de Mensagens de Texto
          </Typography>
          <div className={classes.apiDetails}>
            <Typography className={classes.endpointInfo}>
              <strong>Endpoint:</strong> {getEndpoint()}
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Método:</strong> POST
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Headers:</strong> Token gerado nas conexões, Content-Type: application/json
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Exemplo de Body:</strong>
            </Typography>
            <div className={classes.codeBlock}>
              {`{
  "number": "559999999999",
  "body": "Sua mensagem"
}`}
            </div>
            <Typography>
              <strong>Teste de Envio:</strong>
            </Typography>
            {renderFormMessageText()}
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.sectionTitle}>
            2. Envio de Mensagens de Mídia
          </Typography>
          <div className={classes.apiDetails}>
            <Typography className={classes.endpointInfo}>
              <strong>Endpoint:</strong> {getEndpoint()}
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Método:</strong> POST
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Headers:</strong> Token gerado nas conexões, Content-Type: multipart/form-data
            </Typography>
            <Typography className={classes.endpointInfo}>
              <strong>Exemplo de FormData:</strong>
            </Typography>
            <div className={classes.codeBlock}>
              {`{
  "number": "559999999999",
  "medias": [arquivo]
}`}
            </div>
            <Typography>
              <strong>Teste de Envio:</strong>
            </Typography>
            {renderFormMessageMedia()}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MessagesAPI;
