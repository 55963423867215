// src/components/WhatsAppCard.js
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Badge,
  Chip,
} from "@material-ui/core";
import {
  Edit,
  DeleteOutline,
  CropFree,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { format, parseISO } from "date-fns";
import formatSerializedId from '../../utils/formatSerializedId';
import CustomToolTip from "./CustomToolTip"; // Se você já tiver o CustomToolTip separado, caso contrário, mova o código para este arquivo ou ajuste conforme necessário.
import { i18n } from "../../translate/i18n";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: theme.spacing(2),
    position: 'relative',
  },
  avatar: {
    backgroundColor: green[500],
  },
  statusIcon: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  defaultChip: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const WhatsAppCard = ({
  whatsApp,
  onEdit,
  onDelete,
  onConnect,
  onDisconnect,
  onRequestNewQrCode,
  onOpenQrModal,
}) => {
  const classes = useStyles();

  const renderStatusIcon = () => {
    switch (whatsApp.status) {
      case "DISCONNECTED":
        return (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        );
      case "OPENING":
        return (
          <CustomToolTip
            title={i18n.t("connections.toolTips.connecting.title")}
          >
            <SignalCellularConnectedNoInternet2Bar color="disabled" />
          </CustomToolTip>
        );
      case "qrcode":
        return (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        );
      case "CONNECTED":
        return (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        );
      case "PAIRING":
      case "TIMEOUT":
        return (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        );
      default:
        return null;
    }
  };

  return (
    <Card className={classes.root}>
      {whatsApp.isDefault && (
        <Chip
          label={i18n.t("connections.table.default")}
          color="primary"
          className={classes.defaultChip}
          icon={<CheckCircle />}
        />
      )}
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar aria-label="whatsapp" className={classes.avatar}>
            <img
              src={whatsApp.profileImage}
              alt="WhatsApp Profile"
              style={{ width: '100%', height: '100%', borderRadius: '50%' }}
            />
          </Avatar>

        }
        title={whatsApp.name}
        subheader={whatsApp.number ? formatSerializedId(whatsApp.number) : "-"}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Status: {renderStatusIcon()}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {i18n.t("connections.table.lastUpdate")}: {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            color="primary"
            onClick={() => onOpenQrModal(whatsApp)}
            startIcon={<CropFree />}
          >
            {i18n.t("connections.buttons.qrcode")}
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => onConnect(whatsApp.id)}
            >
              {i18n.t("connections.buttons.tryAgain")}
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => onRequestNewQrCode(whatsApp.id)}
            >
              {i18n.t("connections.buttons.newQr")}
            </Button>
          </>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => onDisconnect(whatsApp.id)}
            >
              {i18n.t("connections.buttons.disconnect")}
            </Button>
          )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
        <IconButton size="small" onClick={() => onEdit(whatsApp)}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={() => onDelete(whatsApp.id)}>
          <DeleteOutline />
        </IconButton>
      </CardActions>
    </Card>
  );
};

WhatsAppCard.propTypes = {
  whatsApp: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onRequestNewQrCode: PropTypes.func.isRequired,
  onOpenQrModal: PropTypes.func.isRequired,
};

export default WhatsAppCard;
