import React, { useEffect, useState } from "react";
import {
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import useDashboard from "../hooks/useDashboard";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.common.white, // Ícone branco para navbar
  },
  popoverPaper: {
    width: 350,
    maxHeight: 500,
    overflowY: "auto",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    backgroundColor: theme.palette.background.paper,
  },
  userItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  statusTextOnline: {
    color: theme.palette.success.main,
    fontWeight: "bold",
  },
  statusTextOffline: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  ratingBox: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
  },
  stars: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },
  star: {
    fontSize: "16px",
    color: "#FFD700", // Cor dourada para as estrelas
    marginRight: "2px",
  },
  ratingText: {
    marginLeft: theme.spacing(1),
    fontSize: "0.85rem",
    color: theme.palette.text.secondary,
  },
}));

const DialogOnlines = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [attendants, setAttendants] = useState([]);
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const fetchAttendants = async () => {
    setLoading(true);
    const dateFrom = moment().startOf("month").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    try {
      const data = await find({ date_from: dateFrom, date_to: dateTo });
      if (Array.isArray(data.attendants)) {
        const sortedAttendants = data.attendants.sort((a, b) =>
          b.online - a.online
        );
        setAttendants(sortedAttendants);
      } else {
        setAttendants([]);
      }
    } catch (error) {
      console.error("Erro ao buscar atendentes:", error);
      setAttendants([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (anchorEl) {
      fetchAttendants();
      const intervalId = setInterval(() => {
        fetchAttendants();
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, [anchorEl]);

  const renderStars = (rating) => {
    const maxRating = 3; // Máximo de nota
    const maxStars = 5; // Quantidade máxima de estrelas
    
    // Converte a nota de 3 para a escala de 5 estrelas
    const convertedRating = (Math.min(rating, maxRating) / maxRating) * maxStars;
    
    const fullStars = Math.floor(convertedRating); // Estrelas completas
    const halfStar = convertedRating % 1 >= 0.5; // Meia estrela se aplicável
    const emptyStars = maxStars - fullStars - (halfStar ? 1 : 0); // Estrelas vazias
    
    return (
      <Box className={classes.stars}>
        {Array(fullStars)
          .fill("★")
          .map((star, index) => (
            <span key={`full-${index}`} className={classes.star}>
              {star}
            </span>
          ))}
        {halfStar && (
          <span key="half" className={classes.star}>
            ☆
          </span>
        )}
        {Array(emptyStars)
          .fill("☆")
          .map((star, index) => (
            <span key={`empty-${index}`} className={classes.star}>
              {star}
            </span>
          ))}
      </Box>
    );
  };
  

  return (
    <div>
      {/* Botão na navbar */}
      <IconButton className={classes.iconButton} onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>

      {/* Dropdown de usuários */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        {loading ? (
          <Typography align="center" style={{ padding: "16px" }}>
            Carregando...
          </Typography>
        ) : attendants.length > 0 ? (
          <List>
            {attendants.map((attendant, index) => (
              <ListItem key={index} className={classes.userItem}>
                <Box display="flex" alignItems="center">
                  <Avatar className={classes.avatar}>
                    {attendant.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <ListItemText
                    primary={attendant.name}
                    secondary={
                      <Typography
                        variant="body2"
                        className={
                          attendant.online
                            ? classes.statusTextOnline
                            : classes.statusTextOffline
                        }
                      >
                        {attendant.online ? "Online" : "Offline"}
                      </Typography>
                    }
                  />
                </Box>
                <Box className={classes.ratingBox}>
                  {renderStars(attendant.rating || 0)}
                  <Typography className={classes.ratingText}>
                    ({attendant.ratingCount || 0} avaliações)
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography align="center" style={{ padding: "16px" }}>
            Nenhum usuário online
          </Typography>
        )}
      </Popover>
    </div>
  );
};

export default DialogOnlines;
