import React, { useEffect } from "react";
import { Modal, Box, IconButton, Typography, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EnhancedModal = ({ openModal, handleClose, contactData }) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose(); // Fecha o modal ao pressionar ESC
      }
    };

    if (openModal) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [openModal, handleClose]);

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(0, 0, 0, 0.9)",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {/* Botão de Fechar */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <CloseIcon style={{ color: "white", fontSize: 28 }} />
        </IconButton>

        {/* Nome e Foto no Topo */}
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            alignItems: "center",
            padding: "8px 12px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
          }}
        >
          <Avatar
            src={contactData.profilePicUrl}
            alt="contact_avatar"
            sx={{ width: 40, height: 40, marginRight: 1 }}
          />
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "white", fontWeight: "bold" }}
          >
            {contactData.contactName}
          </Typography>
        </Box>

        {/* Imagem Central */}
        <img
          src={contactData.profilePicUrl}
          alt="contact_image"
          style={{
            maxWidth: "90%",
            maxHeight: "90%",
            objectFit: "contain",
            borderRadius: "12px",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.5)",
            cursor: "zoom-in",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        />
      </Box>
    </Modal>
  );
};

export default EnhancedModal;
