import React from "react";

import Paper from "@material-ui/core/Paper";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from "@material-ui/lab/Skeleton";
import Rating from '@material-ui/lab/Rating';

import { makeStyles } from "@material-ui/core/styles";
import { green, red, grey } from '@material-ui/core/colors';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    on: {
      color: green[600],
      fontSize: "24px",
    },
    off: {
      color: red[600],
      fontSize: "24px",
    },
    pointer: {
      cursor: "pointer",
    },
    tableContainer: {
      borderRadius: "12px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.background.paper, // Suporte para claro/escuro
      marginTop: theme.spacing(0.5),
    },
    tableHeader: {
      backgroundColor: theme.palette.mode === "dark" ? "#333" : "#128c7e", // Diferente para claro/escuro
      color: theme.palette.common.white,
    },
    tableCellHeader: {
      fontWeight: "bold",
      fontSize: "14px",
      color: theme.palette.common.white,
      textAlign: "center",
    },
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableCell: {
      fontSize: "16px",
      color: theme.palette.text.primary, // Cor do texto adaptada ao tema
      padding: theme.spacing(2),
      borderBottom: "none",
    },
    tableCellIcon: {
      textAlign: "center",
      padding: theme.spacing(1.5),
    },
    skeleton: {
      height: 150,
      borderRadius: "12px",
    },
  }));

export function RatingBox({ rating }) {
    const ratingTrunc = rating === null ? 0 : Math.trunc(rating);
    return <Rating
        defaultValue={ratingTrunc}
        max={3}
        readOnly
        style={{ color: "#FFCE31" }} // Cor personalizada para estrelas de avaliação
    />
}

export default function TableAttendantsStatus(props) {
    const { loading, attendants } = props;
    const classes = useStyles();

    function renderList() {
        return attendants.map((a, k) => (
            <TableRow key={k} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{a.name}</TableCell>
                <TableCell align="center" className={`${classes.tableCell} ${classes.pointer}`} title="1 - Insatisfeito, 2 - Satisfeito, 3 - Muito Satisfeito">
                    <RatingBox rating={a.rating} />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>{formatTime(a.avgSupportTime)}</TableCell>
                <TableCell align="center" className={classes.tableCellIcon}>
                    {a.online ? (
                        <CheckCircleIcon className={classes.on} />
                    ) : (
                        <ErrorIcon className={classes.off} />
                    )}
                </TableCell>
            </TableRow>
        ));
    }

    function formatTime(minutes) {
        return moment().startOf('day').add(minutes, 'minutes').format('HH[h] mm[m]');
    }

    return !loading ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.tableCellHeader}>Nome</TableCell>
                        <TableCell align="center" className={classes.tableCellHeader}>Avaliações</TableCell>
                        <TableCell align="center" className={classes.tableCellHeader}>T.M. de Atendimento</TableCell>
                        <TableCell align="center" className={classes.tableCellHeader}>Status (Atual)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderList()}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <Skeleton variant="rect" className={classes.skeleton} />
    );
}
