import React, { useState, useEffect, useContext } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	CircularProgress,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	DialogActions,
	Button,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		maxWidth: "100%",
		borderRadius: "12px",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		borderRadius: theme.shape.borderRadius,
		boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
	},
	tableHead: {
		backgroundColor: theme.palette.primary.light,
		"& th": {
			color: theme.palette.primary.contrastText,
			fontWeight: "bold",
		},
	},
	loading: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100px",
	},
}));

const GruposList = () => {
	const classes = useStyles();
	const [grupos, setGrupos] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedParticipants, setSelectedParticipants] = useState([]);
	const [selectedGroupName, setSelectedGroupName] = useState("");
	const { whatsApps, loading: loadingWhats } = useContext(WhatsAppsContext);
	const [openCreateDialog, setOpenCreateDialog] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [selectedWhatsApp, setSelectedWhatsApp] = useState("");

	const handleRedirect = (id) => {
		window.location.href = (`/grupos/${id}`); // Redireciona para o caminho desejado
	};
	// Função para buscar os grupos
	const fetchGrupos = async () => {
		setLoading(true);
		try {
			const response = await api.get("/groups");
			setGrupos(response.data.data); // JSON com grupos em `data`
			setError(null);
		} catch (error) {
			console.error("Erro ao buscar grupos:", error);
			setError("Erro ao carregar os grupos.");
		} finally {
			setLoading(false);
		}
	};

	const handleOpenDialog = (participants, groupName) => {
		setSelectedParticipants(participants);
		setSelectedGroupName(groupName);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setSelectedParticipants([]);
		setSelectedGroupName("");
	};

	const handleOpenCreateDialog = () => {
		setOpenCreateDialog(true);
	};

	const handleCloseCreateDialog = () => {
		setOpenCreateDialog(false);
		setGroupName("");
	};

	const handleWhatsAppChange = (event) => {
		setSelectedWhatsApp(event.target.value);
	};


	const handleCreateGroup = async () => {
		try {
			if (groupName.trim() === "") {
				alert("O nome do grupo é obrigatório.");
				return;
			}
			await api.post("/groups/create", { name: groupName, whatsappId: selectedWhatsApp });
			alert("Grupo criado com sucesso!");
			fetchGrupos(); // Atualiza a lista de grupos
			handleCloseCreateDialog();
		} catch (error) {
			console.error("Erro ao criar o grupo:", error);
			alert("Erro ao criar o grupo.");
		}
	};

	const handleDeleteGroup = async (groupId) => {
		if (!window.confirm("Tem certeza que deseja excluir este grupo?")) return;

		try {
			await api.delete(`/group/${groupId}`);
			toast.success("Grupo excluído com sucesso!", {
				toastId: `groupDeleted_${groupId}`,
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			fetchGrupos(); // Atualiza a lista de grupos após a exclusão
		} catch (error) {
			console.error("Erro ao excluir o grupo:", error);
			const errorMsg = error.response?.data?.message || "Erro ao excluir o grupo.";
			toast.error(errorMsg, {
				toastId: `deleteGroupError_${groupId}`,
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};


	useEffect(() => {
		console.log({ loadingWhats, whatsApps: whatsApps });

	}, [whatsApps])

	useEffect(() => {
		fetchGrupos();
	}, []);

	if (loading)
		return (
			<div className={classes.loading}>
				<CircularProgress />
			</div>
		);

	if (error) return <p>{error}</p>;

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Typography variant="h5" color="textPrimary" fontWeight="bold">
					Lista de Grupos
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={handleOpenCreateDialog}
				>
					CRIAR GRUPO
				</Button>

			</div>

			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell>Grupo</TableCell>
							<TableCell>Data Criação</TableCell>
							<TableCell>Participantes</TableCell>
							<TableCell>Admin?</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{grupos.length > 0 ? (
							grupos.map((grupo) => (
								<React.Fragment key={grupo.whatsappId}>
									{grupo.groups.map((g) => (
										<TableRow key={g.id}>

											{/*<TableCell>{g.id}</TableCell>*/}
											<TableCell>
												<div style={{ display: "flex", alignItems: "center" }}>
													<img
														src={g.imageUrl || "https://cdn-icons-png.flaticon.com/512/126/126322.png"}
														alt={g.name}
														style={{ width: 50, height: 50, borderRadius: "50%", marginRight: 10 }}
													/>
													<Typography variant="body1" color="textPrimary">
														{g.name}
													</Typography>
												</div>
											</TableCell>

											<TableCell>
												{g.createdAt
													? new Date(g.createdAt * 1000).toLocaleDateString()
													: "N/A"}
											</TableCell>
											<TableCell
												style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
												onClick={() => handleOpenDialog(g.participants, g.name)}
											>
												{g.participants.length}
											</TableCell>

											<TableCell>{g.isAdmin ? "Sim" : "Não"}</TableCell>

											<TableCell>
												<Button
													variant="contained"
													color="primary"
													onClick={() => { handleRedirect(g.id); }}
													startIcon={<VisibilityIcon />}
													style={{ marginRight: 8 }} // Espaçamento entre os botões
												>
													Ver
												</Button>
												{
													g.isAdmin && (
														<Button
															variant="contained"
															style={{ backgroundColor: 'red', color: 'white' }} // Vermelho personalizado para exclusão
															onClick={() => { handleDeleteGroup(g.id); }}
															startIcon={<DeleteIcon />}
														>
															Excluir
														</Button>
													)
												}
											</TableCell>
										</TableRow>
									))}
								</React.Fragment>
							))
						) : (
							<TableRow>
								<TableCell colSpan={6} align="center">
									Nenhum grupo encontrado.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Dialog para Exibir Participantes */}
			<Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
				<DialogTitle>Participantes do Grupo: {selectedGroupName}</DialogTitle>
				<DialogContent>
					<List>
						{selectedParticipants.map((participant) => (
							<ListItem key={participant.id}>
								<ListItemAvatar>
									<Avatar
										src={participant.foto || "https://cdn-icons-png.flaticon.com/512/126/126322.png"}
										alt={participant.name}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={participant.name || participant.id} // Mostra o nome ou fallback para o ID
									secondary={participant.isAdmin ? "Administrador" : "Membro"} // Exibe o status
								/>
							</ListItem>
						))}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>


			<Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth>
				<DialogTitle>Criar Novo Grupo</DialogTitle>
				<DialogContent>
					{/* Seletor de WhatsApp */}
					<FormControl fullWidth margin="dense">
						<InputLabel>Selecionar WhatsApp</InputLabel>
						<Select
							value={selectedWhatsApp}
							onChange={(e) => setSelectedWhatsApp(e.target.value)}
						>
							{whatsApps.map((whatsApp) => (
								<MenuItem key={whatsApp.id} value={whatsApp.id}>
									{whatsApp.name} - {whatsApp.number}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* Campo Nome do Grupo */}
					<TextField
						autoFocus
						margin="dense"
						label="Nome do Grupo"
						type="text"
						fullWidth
						value={groupName}
						onChange={(e) => setGroupName(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCreateDialog} color="secondary">
						Cancelar
					</Button>
					<Button
						onClick={handleCreateGroup}
						color="primary"
						disabled={!selectedWhatsApp || !groupName} // Desabilitar botão se não houver seleção
					>
						Criar
					</Button>
				</DialogActions>
			</Dialog>


		</div>
	);

};

export default GruposList;
