import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, IconButton, Tooltip, Typography, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AttachFile from "@material-ui/icons/AttachFile";
import { head } from "lodash";
import ConfirmationModal from "../ConfirmationModal";
import MessageVariablesPicker from "../MessageVariablesPicker";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  attachmentPreview: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  attachmentName: {
    marginLeft: theme.spacing(1),
    wordBreak: "break-all",
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  pickerButton: {
    marginTop: theme.spacing(1),
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string()
    .min(5, i18n.t("scheduleModal.validation.minLength"))
    .required(i18n.t("scheduleModal.validation.required")),
  contactId: Yup.number().required(i18n.t("scheduleModal.validation.required")),
  sendAt: Yup.string().required(i18n.t("scheduleModal.validation.required")),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const messageInputRef = useRef();

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      const fetchData = async () => {
        try {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId },
          });
          const customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "Selecione um contato" }, ...customList]);
          }
          if (contactId) {
            setSchedule((prevState) => ({ ...prevState, contactId }));
          }

          if (scheduleId) {
            const { data } = await api.get(`/schedules/${scheduleId}`);
            setSchedule((prevState) => ({
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
            }));
            setCurrentContact(data.contact);
          }
        } catch (err) {
          toastError(err);
        }
      };
      fetchData();
    }
  }, [scheduleId, contactId, open, user]);

  const handleClose = () => {
    onClose();
    setAttachment(null);
    setSchedule(initialState);
    setCurrentContact(initialContact);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload === "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
          //navigate("/schedules");
        }
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const el = messageInputRef.current;
    const firstHalfText = el.value.substring(0, el.selectionStart);
    const secondHalfText = el.value.substring(el.selectionEnd);
    const newCursorPos = el.selectionStart + msgVar.length;

    setValueFunc("body", `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise((r) => setTimeout(r, 100));
    messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
    messageInputRef.current.focus();
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      if (attachmentFile.current) {
        attachmentFile.current.value = null;
      }
    }

    if (schedule.mediaPath) {
      try {
        await api.delete(`/schedules/${schedule.id}/media-upload`);
        setSchedule((prev) => ({
          ...prev,
          mediaPath: null,
          mediaName: "",
        }));
        toast.success(i18n.t("scheduleModal.toasts.deleted"));
        if (typeof reload === "function") {
          reload();
        }
      } catch (err) {
        toastError(err);
      }
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        aria-labelledby="schedule-modal-title"
      >
        <DialogTitle id="schedule-modal-title" className={classes.dialogTitle}>
          {schedule.status === "ERRO"
            ? i18n.t("scheduleModal.titleError")
            : `${i18n.t("scheduleModal.title")} ${capitalize(schedule.status)}`}
        </DialogTitle>
        <Formik
          initialValues={schedule}
          enableReinitialize
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            handleSaveSchedule(values);
            actions.setSubmitting(false);
          }}
        >
          {({ touched, errors, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      value={currentContact}
                      options={contacts}
                      onChange={(e, contact) => {
                        const contactId = contact ? contact.id : "";
                        setFieldValue("contactId", contactId);
                        setCurrentContact(contact ? contact : initialContact);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      getOptionSelected={(option, value) => value.id === option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={i18n.t("scheduleModal.form.contact")}
                          placeholder="Selecione um contato"
                          error={touched.contactId && Boolean(errors.contactId)}
                          helperText={touched.contactId && errors.contactId}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="body"
                      label={i18n.t("scheduleModal.form.body")}
                      variant="outlined"
                      multiline
                      rows={6}
                      inputRef={messageInputRef}
                      error={touched.body && Boolean(errors.body)}
                      helperText={touched.body && errors.body}
                      fullWidth
                    />
                    <MessageVariablesPicker
                      id="msg-var-picker"
                      onClick={(value) => handleClickMsgVar(value, setFieldValue)}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="sendAt"
                      label={i18n.t("scheduleModal.form.sendAt")}
                      type="datetime-local"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <input
                      type="file"
                      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
                      style={{ display: "none" }}
                      ref={attachmentFile}
                      onChange={handleAttachmentFile}
                    />
                    {(attachment || schedule.mediaPath) && (
                      <Paper variant="outlined" className={classes.attachmentPreview}>
                        <AttachFile color="action" />
                        <Typography variant="body2" className={classes.attachmentName}>
                          {attachment ? attachment.name : schedule.mediaName}
                        </Typography>
                        <Tooltip title={i18n.t("scheduleModal.tooltips.deleteAttachment")}>
                          <IconButton
                            onClick={() => setConfirmationOpen(true)}
                            color="secondary"
                            aria-label="delete attachment"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!attachment && !schedule.mediaPath && (
                  <Button
                    color="primary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="outlined"
                    startIcon={<AttachFile />}
                  >
                    {i18n.t("scheduleModal.buttons.attach")}
                  </Button>
                )}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                {(schedule.sentAt === null || schedule.sentAt === "") && (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {scheduleId
                      ? i18n.t("scheduleModal.buttons.okEdit")
                      : i18n.t("scheduleModal.buttons.okAdd")}
                    {isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
