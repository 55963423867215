import { useContext, useEffect } from "react";
import { SocketContext, SocketManager } from "./SocketContext";

const OnlineStatusManager = () => {
    const socketManager = useContext(SocketContext);

    useEffect(() => {
        const userId = localStorage.getItem("userId");

        if (!userId) {
            console.warn("Nenhum usuário encontrado para enviar o status online.");
            return;
        }

        const sendOnlineStatus = () => {
            const companyId = localStorage.getItem("companyId");
            const socket = socketManager.getSocket(companyId);

            socket.emit("online-status", { userId });
            console.log(`Status online enviado para o servidor: UserID ${userId}`);
        };

        // Envia status imediatamente ao conectar
        sendOnlineStatus();

        // Configura envio a cada 1 minuto
        const intervalId = setInterval(() => {
            sendOnlineStatus();
        }, 60000);

        // Limpa intervalo e desconecta ao desmontar
        return () => {
            clearInterval(intervalId);

            // Obtenha o socket mais recente antes de desconectar
            const companyId = localStorage.getItem("companyId");
            const socket = socketManager.getSocket(companyId);


            try {
                socket.disconnect();
            } catch (error) {

            }
            console.log("Socket desconectado.");

        };
    }, [socketManager]);

    return null; // Este componente não precisa renderizar nada
};

export default OnlineStatusManager;
