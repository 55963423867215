import React from "react";
import { Container, Typography, Grid, Button, AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from './imgs/logo-dark.png'; // Substitua pelo caminho da sua imagem

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f9f9f9",
    color: "#333",
    fontFamily: "'Poppins', sans-serif",
  },
  appBar: {
    backgroundColor: "#cae2ff",
    boxShadow: "none",
    padding: theme.spacing(2),
  },
  logo: {
    height: "40px", // Ajuste a altura da imagem da logo
    [theme.breakpoints.down("sm")]: {
      height: "30px", // Tamanho menor para telas pequenas
    },
  },
  navButton: {
    color: "#000",
    marginRight: theme.spacing(2),
    fontSize: "1rem",
    textTransform: "none",
  },
  primaryButton: {
    backgroundColor: "#000",
    color: "#fff",
    padding: theme.spacing(1.5, 3),
    borderRadius: "20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
  secondaryButton: {
    borderColor: "#000",
    color: "#000",
    padding: theme.spacing(1.5, 3),
    borderRadius: "20px",
    textTransform: "none",
    marginLeft: theme.spacing(2),
    borderWidth: "2px",
    "&:hover": {
      borderColor: "#333",
      color: "#333",
    },
  },
  heroSection: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#fdf6f2",
    position: "relative",
    overflow: "hidden",
  },
  heroText: {
    zIndex: 2,
    color: "#000",
    maxWidth: "800px",
  },
  heroTitle: {
    fontSize: "4rem",
    fontWeight: "bold",
    lineHeight: "1.2",
  },
  heroSubtitle: {
    fontSize: "1.2rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  backgroundEffectTop: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "500px",
    width: "500px",
    backgroundColor: "#ff6b6b",
    borderRadius: "0%",
    zIndex: 1,
    opacity: 0.6,
    filter: "blur(100px)",
  },
  backgroundEffect: {
    position: "absolute",
    bottom: "-150px",
    right: "-150px",
    height: "500px",
    width: "500px",
    backgroundColor: "#ff6b6b",
    borderRadius: "0%",
    zIndex: 1,
    opacity: 0.6,
    filter: "blur(100px)",
  },
  featureTitle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  featureSection: {
    padding: theme.spacing(8, 0),
    textAlign: "center",
    margin: "0 auto",
  },
  featureGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  featureCard: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: "1px solid #eee",
    borderRadius: "8px",
    backgroundColor: "#fafafa",
    margin: theme.spacing(2),
    "&:hover": {
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    },
  },
  featureIcon: {
    marginBottom: theme.spacing(2),
    fontSize: "4rem",
  },
  featureDescription: {
    marginBottom: theme.spacing(2),
    color: "#666",
  },
  featureButton: {
    textTransform: "none",
    color: "#008060",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const LandingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Navbar */}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* Substitua o texto por uma imagem */}
          <img src={logo} alt="Logo RODCOMMERCE" className={classes.logo} />
          <Button className={classes.navButton}>Início</Button>
          <Button className={classes.navButton}>Contato</Button>
          <Button className={classes.primaryButton}>Comece agora grátis</Button>
          <Button variant="outlined" className={classes.secondaryButton}>Entrar</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <div className={classes.heroSection}>
        <div className={classes.backgroundEffectTop}></div>
        <div className={classes.heroText}>
          <Typography className={classes.heroTitle}>Simplifique o atendimento ao cliente</Typography>
          <Typography className={classes.heroSubtitle}>
            A RODZAP centraliza o gerenciamento de atendimentos via WhatsApp ajudando sua empresa a otimizar o suporte ao cliente em todos os canais de comunicação.
          </Typography>
          <Button className={classes.primaryButton}>Comece agora grátis</Button>
          <Button variant="outlined" className={classes.secondaryButton}>Saiba mais</Button>
        </div>
        <div className={classes.backgroundEffect}></div>
      </div>

      {/* Feature Section */}
      <section className={classes.featureSection}>
        <Container>
          <Typography className={classes.featureTitle}>Principais Funcionalidades</Typography>
          <Grid container spacing={4} className={classes.featureGrid}>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.featureCard}>
                <Typography className={classes.featureIcon}>⭐</Typography>
                <Typography variant="h6">Flexibilidade de Recursos</Typography>
                <Typography className={classes.featureDescription}>
                  Uma excelente opção para pequenas e médias empresas que precisam gerenciar atendimentos de forma eficiente.
                </Typography>
                <Button className={classes.featureButton}>Saiba mais</Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.featureCard}>
                <Typography className={classes.featureIcon}>⚙️</Typography>
                <Typography variant="h6">Serviços Gerenciados</Typography>
                <Typography className={classes.featureDescription}>
                  Ideal para empresas que desejam suporte contínuo e otimização de suas operações de atendimento ao cliente.
                </Typography>
                <Button className={classes.featureButton}>Saiba mais</Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.featureCard}>
                <Typography className={classes.featureIcon}>🌐</Typography>
                <Typography variant="h6">Acesso via Web</Typography>
                <Typography className={classes.featureDescription}>
                  Permite que sua equipe acesse as plataformas de atendimento de qualquer lugar, facilitando o trabalho remoto.
                </Typography>
                <Button className={classes.featureButton}>Saiba mais</Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.featureCard}>
                <Typography className={classes.featureIcon}>🔗</Typography>
                <Typography variant="h6">Centralização de Canais</Typography>
                <Typography className={classes.featureDescription}>
                  Gerencie atendimentos via WhatsApp centralizando em um único lugar, aumentando a eficiência e o controle.
                </Typography>
                <Button className={classes.featureButton}>Saiba mais</Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default LandingPage;
