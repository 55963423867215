import React, { useEffect, useState } from "react";
import {
    Paper,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Chip,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        padding: theme.spacing(4),
        margin: "auto",
        maxWidth: "100%",
        borderRadius: "12px",
    },
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(4),
    },
    card: {
        maxWidth: 500,
        margin: theme.spacing(2),
        position: 'relative',
        "&:hover": {
            transform: "scale(1.02)",
            boxShadow: theme.shadows[4],
        },
    },
    chip: {
        marginTop: theme.spacing(1),
    },
    avatar: {
        backgroundColor: green[500],
    },
    cardHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    defaultChip: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const UserPatchNotes = () => {
    const classes = useStyles();
    const [patchNotes, setPatchNotes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPatchNotes();
    }, []);

    const fetchPatchNotes = async () => {
        setLoading(true);
        try {
            const { data } = await api.get("/patch-notes");
            setPatchNotes(data.data);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper className={classes.mainPaper} variant="outlined">
            <div className={classes.header}>
                <Typography variant="h4" gutterBottom>
                    O Que Há de Novo?
                </Typography>
            </div>

            {loading ? (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid container spacing={3}>
                    {patchNotes.map((patchNote) => (
                        <Grid item xs={12} sm={6} md={4} key={patchNote.id}>
                            <Card className={classes.card}>
                                <Chip
                                    label={patchNote.status}
                                    color="primary"
                                    className={classes.defaultChip}
                                    icon={<CheckCircle />}
                                />
                                <CardHeader
                                    className={classes.cardHeader}
                                    avatar={
                                        <Avatar aria-label="patch-note" className={classes.avatar}>
                                            {patchNote.version.charAt(0).toUpperCase()}
                                        </Avatar>
                                    }
                                    title={patchNote.version}
                                    subheader={`Lançado em: ${new Date(patchNote.releaseDate).toLocaleDateString()}`}
                                />
                                <CardContent>
                                    <Typography variant="body1" gutterBottom>
                                        {patchNote.description}
                                    </Typography>
                                    {patchNote.changes && Array.isArray(patchNote.changes) && (
                                        <div>
                                            <Typography variant="subtitle1">Mudanças:</Typography>
                                            <ul>
                                                {patchNote.changes.map((change, index) => (
                                                    <li key={index}>
                                                        <strong>{change.title}:</strong> {change.detail}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    <Chip
                                        label={`Status: ${patchNote.status}`}
                                        className={classes.chip}
                                        color={
                                            patchNote.status === "lançada"
                                                ? "primary"
                                                : patchNote.status === "beta"
                                                    ? "secondary"
                                                    : "default"
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Paper>
    );
};

export default UserPatchNotes;