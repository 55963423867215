import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import CustomCard from './CustomCard';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 'calc(100vh - 50px)', // Altura ajustada para considerar a navbar de 64px
        overflow: "hidden", // Esconde o overflow vertical
    },
    boardContainer: {
        width: "100vw", // Largura total da viewport
        height: "100%", // Ocupa a altura total do container ajustado
        overflowX: "auto", // Scroll apenas na horizontal
        overflowY: "hidden", // Sem scroll vertical
    },
    board: {
        maxHeight: '100%',
        overflow: "auto",
        paddingBottom: theme.spacing(2), // Espaçamento extra no final para uma rolagem suave
    }
}));

const Kanban = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [etapas, setEtapas] = useState({ lanes: [] });

    // Função para buscar as etapas do backend
    const fetchEtapas = async () => {
        try {
            const response = await api.get("/tarefas/etapas");
            const etapasData = response.data;
            fetchTarefas(etapasData);
        } catch (error) {
            console.error("Erro ao buscar etapas:", error);
        }
    };

    // Função para buscar as tarefas do backend
    const fetchTarefas = async (etapasData) => {
        try {
            const response = await api.get("/tarefas");
            const tarefasData = response.data;

            // Organiza as tarefas em cards dentro das respectivas etapas (lanes)
            const lanes = etapasData.map(etapa => {
                const etapaTarefas = tarefasData.filter(tarefa => tarefa.etapaId === etapa.id);
                const cards = etapaTarefas.map(tarefa => ({
                    id: tarefa.id.toString(),
                    title: tarefa.titulo,
                    description: tarefa.descricao || '',
                    label: tarefa.status,
                }));

                return {
                    id: etapa.id.toString(),
                    title: etapa.nome,
                    label: `${etapa.ordem}`,
                    cards: cards.length > 0 ? cards : [], // Mesmo que não tenha cards, garante um array vazio
                };
            });

            setEtapas({ lanes });
        } catch (error) {
            console.error("Erro ao buscar tarefas:", error);
        }
    };

    // Função para atualizar a tarefa no backend
    const handleUpdateTask = async (taskId, newEtapaId, newOrder) => {
        try {
            await api.patch(`/tarefas/${taskId}`, {
                etapaId: newEtapaId,
                ordem: newOrder
            });
        } catch (error) {
            console.error("Erro ao atualizar tarefa:", error);
        }
    };

    // Função chamada quando um card é movido para outra lane (etapa)
    const handleCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
        console.log(`Card ${cardId} moved from lane ${fromLaneId} to lane ${toLaneId} at position ${index}`);
        handleUpdateTask(cardId, toLaneId, index);
    };

    // Função chamada quando um card é movido dentro da mesma lane
    const handleCardMoveWithinLane = (laneId, cardId, index) => {
        console.log(`Card ${cardId} moved within lane ${laneId} to position ${index}`);
        handleUpdateTask(cardId, laneId, index);
    };

    // useEffect para buscar as etapas quando o componente for montado
    useEffect(() => {
        fetchEtapas();
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.boardContainer}>
                <Board
                    data={etapas}
                    className={classes.board}
                    style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
                    components={{ Card: CustomCard }}
                    draggable
                    onCardMoveAcrossLanes={handleCardMoveAcrossLanes}
                    onCardMoveWithinLane={handleCardMoveWithinLane}
                />
            </div>
        </div>
    );
};

export default Kanban;
