import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Divider, } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles"; // Importar useTheme
import NewTicketModal from "../NewTicketModal";
import { getInitials } from '../../helpers/getInitials';



const VCardPreview = ({ contact, numbers }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const theme = useTheme(); // Usar o hook useTheme para acessar o tema

    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });

    const [selectedQueue, setSelectedQueue] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [isContactValid, setContactValid] = useState(true);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});

    const handleQueueSelection = async (queueId) => {
        setSelectedQueue(queueId);
        setModalOpen(false);
        if (queueId !== "") {
            await createTicket(queueId);
        }
    };

    const renderQueueModal = () => {
        return (
            <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: theme.palette.background.paper,
                    padding: "20px",
                    outline: "none",
                }}>
                    <h2>Selecione a Fila</h2>
                    {user.queues.map((queue) => (
                        <div key={queue.id}>
                            <Button onClick={() => handleQueueSelection(queue.id)}>
                                {queue.name}
                            </Button>
                        </div>
                    ))}
                </div>
            </Modal>
        );
    };


    useEffect(() => {
        const fetchContacts = async () => {
            try {
                let contactObj = {
                    name: contact,
                    number: numbers.replace(/\D/g, ""),
                    email: ""
                };

                const { data } = await api.post("/contacts", contactObj);

                if (data.alreadyExists) {
                    setContact(data.existingContact);
                } else if (!data.alreadyExists && data.id) {
                    setContact(data);
                } else {
                    setContact(contactObj);
                }

                if (data.invalido) {

                    //console.log("CONTATO INVALIDOOOOO");
                    //console.log(data.error);
                    // SHOULD RETURN ANOTHER VIEW
                    // Update the button text and disable the button
                    setContactValid(false);

                }

                console.log("XXXXXXXXXXXXXXXX");
                console.log(data);
                console.log(contactObj);

            } catch (err) {
                console.log({ err });
                toastError(err);
            }
        };

        const delayDebounceFn = setTimeout(fetchContacts, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleNewChat = () => {
        if (selectedQueue === "") {
            setModalOpen(true);
        } else {
            createTicket();
        }
    };

    const createTicket = async (queueId) => {
        try {
            let contactId = selectedContact.id;

            // If selectedContact.id is null, fetch the contact ID from the server
            if (!contactId) {
                const contactObj = {
                    name: selectedContact.name,
                    number: selectedContact.number,
                    email: ""
                };

                const { data } = await api.post("/contacts", contactObj);
                contactId = data.existingContact.id;
            }

            //console.log(contactId);

            const { data: ticket } = await api.post("/tickets", {
                contactId,
                queueId,
                userId: user.id,
                status: "open",
            });

            //console.log(user);
            // console.log(selectedContact.id);
            // console.log(ticket.id);
            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    return (
        <>
            {renderQueueModal()}
            <div
                style={{
                    maxWidth: "410px",
                    backgroundColor: theme.palette.background.default || "#f5f5f5", // Fundo cinza claro
                    borderRadius: "10px",
                    padding: "15px",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.1)", // Sombreamento leve
                    margin: "10px auto",
                }}
            >
                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    initialContact={selectedContact}
                    onClose={(ticket) => {
                        handleCloseOrOpenTicket(ticket);
                    }}
                />
                <Grid container spacing={2} alignItems="center">
                    {/* Avatar */}
                    <Grid item xs={2}>
                        <Avatar
                            src={selectedContact.profilePicUrl}
                            style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                                margin: "5px",
                            }}
                        >
                            {getInitials(selectedContact.name || "")}
                        </Avatar>
                    </Grid>

                    {/* Informações do Contato */}
                    <Grid item xs={10}>
                        <Typography
                            style={{
                                marginTop: "5px",
                                fontWeight: "bold",
                                color: theme.palette.text.primary,
                                fontSize: "16px",
                            }}
                            variant="subtitle1"
                            gutterBottom
                        >
                            {selectedContact.name || "Nome não informado"}
                        </Typography>
                        <Typography
                            style={{
                                color: theme.palette.text.secondary,
                                fontSize: "14px",
                            }}
                            variant="body2"
                            gutterBottom
                        >
                            <strong>Telefone:</strong> {selectedContact.number || "Número não disponível"}
                        </Typography>
                    </Grid>

                    {/* Divider */}
                    <Grid item xs={12}>
                        <Divider style={{ margin: "10px 0" }} />
                    </Grid>

                    {/* Botão */}
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{
                                backgroundColor: isContactValid ? "#25D366" : "#cccccc", // Verde WhatsApp ou cinza desativado
                                color: "#ffffff", // Texto branco
                                fontWeight: "bold",
                                fontSize: "14px",
                                margin: "10px 0",
                                padding: "10px",
                                borderRadius: "5px",
                            }}
                            onClick={() => {

                                console.log({
                                    click: selectedContact
                                });


                                setContactTicket(selectedContact);
                                setContact(selectedContact);
                                setNewTicketModalOpen(true);
                            }}
                            disabled={!selectedContact.number || !isContactValid}
                        >
                            {isContactValid
                                ? "Conversar (Novo Ticket)"
                                : "CONTATO FORA DO WHATSAPP"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );

};

export default VCardPreview;