import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InsertChartIcon from "@material-ui/icons/InsertChart"; // Ícone de gráfico de barras
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles"; // Hook para acessar o tema
import { ChartsDate } from "../pages/Dashboard/ChartsDate";
import { ChatsUser } from "../pages/Dashboard/ChartsUser";

const useStyles = makeStyles((theme) => ({
  fixedHeightPaper2: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#ffffff", // Fundo dinâmico
    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000", // Texto dinâmico
    boxShadow:
      theme.palette.mode === "dark"
        ? "0px 4px 10px rgba(255, 255, 255, 0.2)"
        : "0px 4px 10px rgba(0, 0, 0, 0.1)", // Sombras adaptadas
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.mode === "dark" ? "#333" : theme.palette.primary.main, // Título dinâmico
    color: theme.palette.common.white,
  },
  iconButton: {
    color: theme.palette.mode === "dark" ? "#ffffff" : theme.palette.common.white, // Ícone dinâmico
  },
  dialogContent: {
    backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#ffffff", // Fundo do conteúdo
  },
}));

const FullScreenDialog = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme(); // Acessa o tema atual
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Botão para abrir o diálogo na Navbar */}
      <IconButton color="inherit" onClick={handleOpen}>
        <InsertChartIcon className={classes.iconButton} />
      </IconButton>

      {/* Diálogo em tela cheia */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#ffffff", // Fundo do diálogo
            color: theme.palette.mode === "dark" ? "#ffffff" : "#000000", // Texto do diálogo
          },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <span>Relatórios de Gráficos</span>
          <IconButton edge="end" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
        
                <ChatsUser />
           
            </Grid>
            <Grid item xs={12}>
          
                <ChartsDate />
    
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FullScreenDialog;
