// src/pages/Schedules.js ou .jsx ou .tsx
import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ScheduleModal from "../../components/ScheduleModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import moment from "moment";
import { capitalize } from "lodash";
import { SocketContext } from "../../context/Socket/SocketContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TableContainer } from "@material-ui/core";

const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

// Helper function to map status to Chip colors
const getStatusChip = (status) => {
  switch (status.toUpperCase()) {
    case "PENDENTE":
      return <Chip label={capitalize(status)} color="default" />;
    case "AGENDADA":
      return <Chip label={capitalize(status)} color="primary" />;
    case "ENVIADA":
      return <Chip label={capitalize(status)} color="secondary" />;
    case "ERRO":
      return (
        <Chip
          label={capitalize(status)}
          color="secondary"
          style={{ backgroundColor: "#f44336", color: "#fff" }}
        />
      );
    default:
      return <Chip label={capitalize(status)} />;
  }
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_SCHEDULES":
      // Ordena os schedules, colocando "PENDENTE" primeiro e depois por createdAt DESC
      const sortedSchedules = [...action.payload].sort((a, b) => {
        if (a.status === "PENDENTE" && b.status !== "PENDENTE") return -1;
        if (a.status !== "PENDENTE" && b.status === "PENDENTE") return 1;
        // Ordena por createdAt DESC
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const newSchedules = [];
      sortedSchedules.forEach((schedule) => {
        const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
        if (scheduleIndex !== -1) {
          state[scheduleIndex] = schedule;
        } else {
          newSchedules.push(schedule);
        }
      });
      return [...state, ...newSchedules];

    case "UPDATE_SCHEDULES":
      const updatedSchedule = action.payload;
      const updatedIndex = state.findIndex((s) => s.id === updatedSchedule.id);
      if (updatedIndex !== -1) {
        state[updatedIndex] = updatedSchedule;
      } else {
        state.unshift(updatedSchedule); // Adiciona no início se não existir
      }
      // Reordena após atualização
      return [...state].sort((a, b) => {
        if (a.status === "PENDENTE" && b.status !== "PENDENTE") return -1;
        if (a.status !== "PENDENTE" && b.status === "PENDENTE") return 1;
        // Ordena por createdAt DESC
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    case "DELETE_SCHEDULE":
      return state.filter((s) => s.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "auto",
    maxHeight: "80vh",
    ...theme.scrollbarStyles,
  },
  tableContainer: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)", // Sombra para destacar a tabela
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
    "& th": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover, // Cor de fundo ao passar o mouse
    },
  },
  actionsCell: {
    minWidth: "150px",
  },
  timeRemaining: {
    fontWeight: "bold",
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  chipError: {
    backgroundColor: "#f44336",
    color: "#fff",
  },
}));

const Schedules = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { socket } = useContext(SocketContext); // Use SocketContext aqui

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(
    getUrlParam("contactId") ? +getUrlParam("contactId") : null
  );

  useEffect(() => {
    document.title = `RODTICKET - Agendamentos`;
    return () => {
      document.title = "RODTICKET";
    };
  }, []);

  // Estado para o tempo atual, atualizado a cada segundo
  const [currentTime, setCurrentTime] = useState(moment());

  const fetchSchedules = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/schedules/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
      setHasMore(data.hasMore);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, [searchParam, pageNumber]);

  const handleOpenScheduleModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenScheduleModal();
    }
  }, [contactId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    fetchSchedules();
  }, [searchParam, pageNumber, fetchSchedules]);

  useEffect(() => {
    handleOpenScheduleModalFromContactId();

    if (socket) {
      // Escutar eventos específicos para a empresa do usuário
      const companyId = user.companyId; // Supondo que o usuário tenha companyId
      socket.emit("joinRoom", `company-${companyId}-mainchannel`);
      socket.emit("joinRoom", `company-${companyId}-schedule`);

      // Escutar evento para atualização de mensagens
      socket.on(`company-${companyId}-message`, (data) => {
        if (data.action === "create") {
          // Opcional: Atualizar o estado ou notificar o usuário
          // Exemplo: Adicionar a nova mensagem ao ticket relacionado
          console.log("Nova mensagem recebida:", data.message);
        }
      });

      // Escutar evento para atualização de schedules
      socket.on(`company-${companyId}-schedule`, (data) => {
        if (data.action === "update" || data.action === "create") {
          dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedule });
        }

        if (data.action === "delete") {
          dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
        }
      });

      // Também escutar eventos gerais de schedule (caso necessário)
      socket.on("schedule", (data) => {
        if (data.action === "update" || data.action === "create") {
          dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedules });
        }

        if (data.action === "delete") {
          dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
        }
      });

      return () => {
        socket.off(`company-${companyId}-message`);
        socket.off(`company-${companyId}-schedule`);
        socket.off("schedule");
      };
    }
  }, [socket, handleOpenScheduleModalFromContactId, user.companyId]);

  const cleanContact = () => {
    setContactId(null);
  };

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
      dispatch({ type: "DELETE_SCHEDULE", payload: scheduleId });
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    setSearchParam("");
    setPageNumber(1);
    dispatch({ type: "RESET" });
    await fetchSchedules();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const truncate = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len) + "...";
    }
    return str;
  };

  // Atualizar o tempo atual a cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000); // Atualiza a cada segundo

    return () => clearInterval(timer);
  }, []);

  // Função para calcular e formatar o tempo restante
  const getTimeRemaining = (schedule) => {
    const duration = moment.duration(moment(schedule.sendAt).diff(currentTime));
    if (duration.asSeconds() <= 0) {
      return getStatusChip(schedule.status);
    }
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
      >
        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        reload={fetchSchedules}
        aria-labelledby="form-dialog-title"
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        cleanContact={cleanContact}
      />
      <MainHeader>
        <Title>{i18n.t("schedules.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            className={classes.searchField}
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenScheduleModal}
          >
            {i18n.t("schedules.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

       <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">{i18n.t("schedules.table.contact")}</TableCell>
              <TableCell align="center">{i18n.t("schedules.table.body")}</TableCell>
              <TableCell align="center">{i18n.t("schedules.table.sendAt")}</TableCell>
              <TableCell align="center">Tempo Restante</TableCell>
              <TableCell align="center">{i18n.t("schedules.table.status")}</TableCell>
              <TableCell align="center" className={classes.actionsCell}>
                {i18n.t("schedules.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule) => (
              <TableRow key={schedule.id} className={classes.tableRow}>
                <TableCell align="center">
                  <Chip
                    label={`${schedule.contact.id} - ${capitalize(
                      schedule.contact.name
                    )}`}
                    className={classes.chipPrimary}
                  />
                </TableCell>
                <TableCell align="center" title={schedule.body}>
                  {truncate(schedule.body, 25)}
                </TableCell>
                <TableCell align="center">
                  {moment(schedule.sendAt).format("DD/MM/YYYY HH:mm:ss")}
                </TableCell>
                <TableCell align="center" className={classes.timeRemaining}>
                  {getTimeRemaining(schedule)}
                </TableCell>
                <TableCell align="center">
                  {getStatusChip(schedule.status)}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => handleEditSchedule(schedule)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeletingSchedule(schedule);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={6} avatar={false} />}
          </TableBody>
        </Table>
      </TableContainer>

    </MainContainer>
  );
};

export default Schedules;
