import React, { useEffect, useReducer, useState, useContext } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ModuloModal from "../../components/ModuloModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import { SocketContext } from "../../context/Socket/SocketContext";
import ModuloChart from "./ModuloChart";
import TableContainer from "@material-ui/core/TableContainer"; // Import para encapsular a tabela

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "auto",
    height: "40%",
    ...theme.scrollbarStyles,
  },
  tableContainer: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)", // Sombra e bordas arredondadas
  },
  tableHead: {
    backgroundColor: theme.palette.primary.light,
    "& th": {
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover, // Destaque ao passar o mouse
    },
  },
}));



const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_MODULOS":
      return [...action.payload];
    case "UPDATE_MODULOS":
      const updatedModulo = action.payload;
      return state.map((m) => (m.id === updatedModulo.id ? updatedModulo : m));
    case "DELETE_MODULO":
      return state.filter((m) => m.id !== action.payload);
    case "ADD_MODULO":
      return [action.payload, ...state];
    default:
      return state;
  }
};

const Modulos = () => {
  const classes = useStyles();
  const [modulos, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [moduloModalOpen, setModuloModalOpen] = useState(false);
  const [selectedModulo, setSelectedModulo] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const socketManager = useContext(SocketContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/modulos");
        dispatch({ type: "LOAD_MODULOS", payload: data });
      } catch (err) {
        toastError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-modulo`, (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MODULO", payload: data.modulo });
      } else if (data.action === "update") {
        dispatch({ type: "UPDATE_MODULOS", payload: data.modulo });
      } else if (data.action === "delete") {
        dispatch({ type: "DELETE_MODULO", payload: data.moduloId });
      }
    });

    return () => {
      socket.off(`company-${companyId}-modulo`);
    };
  }, [socketManager]);

  const handleOpenModuloModal = () => {
    setModuloModalOpen(true);
    setSelectedModulo(null);
  };

  const handleCloseModuloModal = () => {
    setModuloModalOpen(false);
    setSelectedModulo(null);
  };

  const handleEditModulo = (modulo) => {
    setSelectedModulo(modulo);
    setModuloModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedModulo(null);
  };

  const handleDeleteModulo = async (moduloId) => {
    try {
      await api.delete(`/modulos/${moduloId}`);
      toast.success(i18n.t("Modulo deletado com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedModulo(null);
  };
  return (
    <MainContainer>
      <div className={classes.container}>
        <ConfirmationModal
          title={selectedModulo && `Excluir: ${selectedModulo.nome}?`}
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteModulo(selectedModulo.id)}
        >
          Após excluir, não tem como recuperar!
        </ConfirmationModal>
        <ModuloModal
          open={moduloModalOpen}
          onClose={handleCloseModuloModal}
          moduloId={selectedModulo?.id}
        />
        <MainHeader>
          <Title>Módulos</Title>
          <MainHeaderButtonsWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModuloModal}
            >
              Adicionar Módulo
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
  
        {/* Encapsulando a tabela */}
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">Qtde. Tickets</TableCell>
                <TableCell align="center">
                  {i18n.t("queues.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modulos.map((modulo) => (
                <TableRow key={modulo.id} className={classes.tableRow}>
                  <TableCell align="center">{modulo.id}</TableCell>
                  <TableCell align="center">{modulo.nome}</TableCell>
                  <TableCell align="center">{modulo.ticketTrakingCount}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar">
                      <IconButton
                        size="small"
                        onClick={() => handleEditModulo(modulo)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedModulo(modulo);
                          setConfirmModalOpen(true);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </TableBody>
          </Table>
        </TableContainer>
  
        {/* Gráfico ocupando 50% da tela */}
        <div className={classes.chartContainer}>
          <ModuloChart modulos={modulos} />
        </div>
      </div>
    </MainContainer>
  );
  
};

export default Modulos;
