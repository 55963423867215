import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, Typography, Grid, Avatar } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import AddIcon from '@material-ui/icons/Add'; // Importa o ícone de "+"
import { generateColor } from "../../helpers/colorGenerator";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer"
  },
  addButton: {
    marginBottom: theme.spacing(2),
    background: "#1976d2",
    color: "white",
    "&:hover": {
      backgroundColor: "#1565c0",
    }
  }
}));

const CRMLeads = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tags, setTags] = useState([]); // Armazena as tags
  const [reloadData, setReloadData] = useState(false);
  const [leads, setLeads] = useState([]); // Armazena os leads
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  // Estado do modal "Adicionar Lead"
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [sending, setSending] = useState(false);

  const { user } = useContext(AuthContext);

  // Função para buscar tags com isCRM=true
  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/crmleads");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
    } catch (error) {
      console.log(error);
    }
  };

  // Função para buscar leads para cada tag
  const fetchLeads = async () => {
    try {
      const response = await api.get("/crmleads"); // Supondo que você tenha uma rota que retorne todos os leads da empresa
      setLeads(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Função para abrir o modal com a tag selecionada
  const handleOpenModalWithTag = (tag) => {
    setSelectedTag(tag);  // Define a tag selecionada
    setModalOpen(true);    // Abre o modal
  };

  const cardStyles = {

  };
  
  const contentStyle = {
    display: 'flex',
    flexDirection: 'row', // Elementos lado a lado
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: '10px' // Espaço entre conteúdo e botão
  };
  const popularCards = () => {
    const lanes = tags.map(tag => {
      const filteredLeads = leads.filter(lead => lead.tag_id === tag.id);
  
      return {
        id: tag.id.toString(), // ID da tag para a lane
        title: (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">{tag.name}</Typography>
            <AddIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenModalWithTag(tag)}
            />
          </div>
        ),
        label: tag.id.toString(), // Etiqueta opcional
        cards: filteredLeads.map(lead => ({
          id: lead.id.toString(), // MUITO IMPORTANTE: 'id' aqui deve ser o ID do lead
          label: "Lead nº " + lead.id, // Texto visível no card
          description: (
            <div style={cardStyles}>
              <div style={contentStyle}>
                {lead.Contact.profilePicUrl && (
                  <Avatar
                    src={lead.Contact.profilePicUrl}
                    alt={lead.Contact.name}
                    style={{ backgroundColor: generateColor(lead.Contact?.number), color: "white", fontWeight: "bold" }}
                  />
                )}
                <div>
                  <Typography variant="body1" component="p">{lead.Contact.name}</Typography>
                  <Typography variant="body2" component="p">{lead.Contact.number}</Typography>
                </div>
              </div>
              <Button
                className={classes.button}
                onClick={() => handleCardClick(lead.id)}
                style={{ width: '100%', marginTop: 'auto' }}
              >
                Ver Lead
              </Button>
            </div>
          ),
          title: lead.Contact.name,
          draggable: true,
        })),
        style: { backgroundColor: "#075E54", color: "white" }
      };
    });
  
    return { lanes };
  };
  
    

  // Função para redirecionar ao visualizar um lead
  const handleCardClick = (id) => {
    history.push('/crmleads/' + id); // Ajuste conforme necessário para a rota
  };

  useEffect(() => {
    fetchTags(); // Buscar tags
    fetchLeads(); // Buscar leads
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });

  useEffect(() => {
    const data = popularCards();
    setFile(data); // Atualizar os dados para exibir no Board
  }, [tags, leads]);

  // Função de mover o card entre lanes
  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {

    const leadId = targetLaneId;
    const tagID = sourceLaneId;

    try {
      // Aqui, cardId é o ID do lead, e targetLaneId é o novo ID da tag
      await api.put(`/crmleads/${leadId}/move`, { tag_id: tagID });
      toast.success('Lead movido com sucesso!');
      fetchLeads(); // Atualizar os leads após mover
    } catch (err) {
      console.error(err);
      toast.error('Erro ao mover lead.');
    }
  };
  

  // Abrir modal de adicionar Lead
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Fechar modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedContact(null);
    setSelectedTag(null);
  };

  // Buscar contatos enquanto digita no autocomplete
  const handleSearchContacts = async (value) => {
    if (value.length < 3) return;
    setLoadingContacts(true);
    try {
      const { data } = await api.get("/contacts", { params: { searchParam: value } });
      setContacts(data.contacts);
    } catch (error) {
      toast.error("Erro ao buscar contatos");
    }
    setLoadingContacts(false);
  };

  // Função para adicionar o Lead
  const handleAddLead = async () => {
    if (!selectedContact || !selectedTag) {
      toast.error("Por favor, selecione um contato e uma tag.");
      return;
    }

    setSending(true);
    try {
      await api.post("/crmleads", {
        contact_id: selectedContact.id,
        tag_id: selectedTag.id,
      });
      toast.success("Lead adicionado com sucesso!");
      fetchLeads(); // Recarregar os leads após adicionar
      setSending(false);
      handleCloseModal();
    } catch (error) {
      toast.error("Erro ao adicionar lead");
      setSending(false);
    }
  };

  return (
    <div className={classes.root}>


      <Board
        data={file}
        onCardMoveAcrossLanes={handleCardMove}
        style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
      />

      {/* Modal de adicionar Lead */}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Adicionar Lead</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}> {/* Container para alinhar os itens */}

            {/* Campo de Buscar Contato */}
            <Grid item xs={12} sm={6}> {/* O input ocupará metade da linha em telas médias */}
              <Autocomplete
                options={contacts}
                getOptionLabel={(option) => `${option.name} - ${option.number}`}
                onInputChange={(e, value) => handleSearchContacts(value)}
                onChange={(e, newValue) => setSelectedContact(newValue)}
                loading={loadingContacts}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar Contato"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingContacts ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {/* Campo de Selecionar Tag */}
            <Grid item xs={12} sm={6}> {/* O input ocupará a outra metade */}
              <Autocomplete
                options={tags}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => setSelectedTag(newValue)}
                value={selectedTag} // Aqui já seleciona a tag automaticamente
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecionar Tag"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner
            variant="contained"
            color="primary"
            onClick={handleAddLead}
            loading={sending}
          >
            Adicionar
          </ButtonWithSpinner>
          <Button onClick={handleCloseModal} color="secondary">Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CRMLeads;
