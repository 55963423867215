import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../services/api";
import { UsersFilter } from "../../components/UsersFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    maxWidth: "100%",
    borderRadius: "12px",
    backgroundColor: "#f9f9f9",
  },
  formControl: {
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
    color: "#333",
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1.5),
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  textField: {
    backgroundColor: "#fff",
  },
  section: {
    marginTop: theme.spacing(3),
  },
  quillContainer: {
    height: "150px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    overflow: "hidden",
  },
}));

const NovaTarefa = () => {
  const classes = useStyles();
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ordem, setOrdem] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [etapaId, setEtapaId] = useState("");
  const [etapas, setEtapas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEtapas = async () => {
      setLoading(true);
      try {
        const response = await api.get("/tarefas/etapas");
        setEtapas(response.data);
      } catch (error) {
        console.error("Erro ao buscar etapas:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEtapas();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post("/tarefas", {
        titulo,
        descricao,
        ordem: parseInt(ordem),
        etapaId,
        assignedUsers: selectedUsers,
      });
      alert("Tarefa cadastrada com sucesso!");
      setTitulo("");
      setDescricao("");
      setOrdem(1);
      setSelectedUsers([]);
      setEtapaId("");
    } catch (error) {
      console.error("Erro ao cadastrar tarefa:", error);
      alert("Erro ao cadastrar tarefa.");
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper className={classes.root} elevation={0}>
        <Typography variant="h5" component="h1" className={classes.title}>
          Cadastrar Nova Tarefa
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                fullWidth
                label="Título da Tarefa"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                required
                className={classes.textField}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="etapa-label">Etapa</InputLabel>
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Select
                    labelId="etapa-label"
                    value={etapaId}
                    onChange={(e) => setEtapaId(e.target.value)}
                    label="Etapa"
                    required
                  >
                    {etapas.map((etapa) => (
                      <MenuItem key={etapa.id} value={etapa.id}>
                        {etapa.nome}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>

            <Divider />

            <Grid item xs={12} className={classes.section}>
              <Typography variant="body1" style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Atribuir Usuários
              </Typography>
              <UsersFilter
                onFiltered={(users) => setSelectedUsers(users)}
                initialUsers={selectedUsers}
              />
            </Grid>

            <Divider />

            <Grid item xs={12} className={classes.section}>
              <Typography variant="body1" style={{ marginBottom: "10px", fontWeight: "bold" }}>
                Descrição da Tarefa
              </Typography>
              <div className={classes.quillContainer}>
                <ReactQuill
                  theme="snow"
                  value={descricao}
                  onChange={setDescricao}
                  style={{ height: "100%" }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submitButton}
              >
                Cadastrar Tarefa
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default NovaTarefa;
