import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List, Typography } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import TableChartIcon from "@material-ui/icons/TableChart";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import EventIcon from "@material-ui/icons/Event";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { SocketContext } from "../context/Socket/SocketContext";
import { isArray } from "lodash";
import api from "../services/api";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import toastError from "../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import usePlans from "../hooks/usePlans";
import useVersion from "../hooks/useVersion";
import { AllInclusive, AssignmentOutlined, DeviceHubOutlined, ViewModuleOutlined } from "@material-ui/icons";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CategoryIcon from "@material-ui/icons/Category";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import { Campaign, ShapeLine, Webhook } from "@mui/icons-material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import DescriptionIcon from "@material-ui/icons/Description"; // Ícone para Patch Notes
import VisibilityIcon from "@material-ui/icons/Visibility"; // Ícone para Detalhes

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }

  return state;
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [openTaskSubmenu, setOpenTaskSubmenu] = useState(false);
  const [openPatchNotesSubmenu, setOpenPatchNotesSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const history = useHistory();
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);

  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();

  const [version, setVersion] = useState(false);

  const { getVersion } = useVersion();

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  return (
    <div onClick={drawerClose}>


      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<ConfirmationNumberOutlinedIcon />}
      />

      <ListItemLink
        to="/grupos"
        primary="Grupos"
        icon={<GroupOutlinedIcon />} // Ícone atualizado para representar grupos
      />

      <ListItemLink
        to="/flow"
        primary={"Flows"}
        icon={<SmartToyOutlinedIcon />}
      />

      {showKanban && (
        <ListItemLink
          to="/kanban"
          primary={i18n.t("Kanban")}
          icon={<TableChartIcon />}
        />
      )}

      {/*showKanban && (
        <ListItemLink
          to="/crmleads"
          primary={i18n.t("CRM - Leads")}
          icon={<BusinessCenterIcon />}
        />
      )*/}

      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<FlashOnIcon />}
      />


      { /*<ListItemLink
        to="/todolist"
        primary={i18n.t("Tarefas")}
        icon={<AssignmentOutlinedIcon />}
      /> */ }

      <>
        <ListItem
          button
          onClick={() => setOpenTaskSubmenu((prev) => !prev)}
        >
          <ListItemIcon>
            <AssignmentIcon /> {/* Ícone de Tarefas */}
          </ListItemIcon>
          <ListItemText
            primary="Tarefas"
          />
          {openTaskSubmenu ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          style={{ paddingLeft: 15 }}
          in={openTaskSubmenu}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            { /*<ListItem onClick={() => history.push("/tarefas")} button>
                   <ListItemIcon>
                      <ListAltIcon /> 
                    </ListItemIcon>
                    <ListItemText primary="Listagem" />
                  </ListItem>*/ }
            <ListItem onClick={() => history.push("/tarefascrm")} button>
              <ListItemIcon>
                <ViewColumnIcon color="primary" /> {/* Ícone de Kanban */}
              </ListItemIcon>
              <ListItemText primary="Listagem" />
            </ListItem>

            <ListItem onClick={() => history.push("/etapastarefas")} button>
              <ListItemIcon>
                <CategoryIcon color="secondary" /> {/* Ícone para representar Etapas */}
              </ListItemIcon>
              <ListItemText primary="Etapas" />
            </ListItem>

            <ListItem onClick={() => history.push("/nova-tarefa")} button>
              <ListItemIcon>
                <AddCircleOutlineIcon color="action" /> {/* Ícone para Nova Tarefa */}
              </ListItemIcon>
              <ListItemText primary="Novo" />
            </ListItem>

          </List>
        </Collapse>
      </>

      <>
        <ListItem
          button
          onClick={() => setOpenPatchNotesSubmenu((prev) => !prev)}
        >
          <ListItemIcon>
            <DescriptionIcon /> {/* Ícone de Patch Notes */}
          </ListItemIcon>
          <ListItemText
            primary="Patch Notes"
          />
          {openPatchNotesSubmenu ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>
        <Collapse
          style={{ paddingLeft: 15 }}
          in={openPatchNotesSubmenu}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem onClick={() => history.push("/patch-notes")} button>
              <ListItemIcon>
                <ListAltIcon color="primary" /> {/* Ícone de Listagem */}
              </ListItemIcon>
              <ListItemText primary="Listagem" />
            </ListItem>

            <ListItem onClick={() => history.push("/patch-notes/create")} button>
              <ListItemIcon>
                <AddCircleOutlineIcon color="action" /> {/* Ícone para Criar */}
              </ListItemIcon>
              <ListItemText primary="Novo" />
            </ListItem>

            <ListItem onClick={() => history.push("/patch-notes/user")} button>
              <ListItemIcon>
                <VisibilityIcon color="secondary" /> {/* Ícone de Detalhes */}
              </ListItemIcon>
              <ListItemText primary="Detalhes" />
            </ListItem>
          </List>
        </Collapse>
      </>


      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
      />

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon />}
      />

      <ListItemLink
        to="/chats"
        primary={i18n.t("mainDrawer.listItems.chats")}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <ChatBubbleOutlineIcon />
          </Badge>
        }
      />

      <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpOutlineIcon />}
      />

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            <ListSubheader
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20
              }}
              inset
              color="inherit">
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>

            <Can
              role={user.profile}
              perform="dashboard:view"
              yes={() => (
                <ListItemLink
                  to="/dashboard"
                  primary="Dashboard"
                  icon={<DashboardIcon />}
                />
              )}
            />

            <Can
              role={user.profile}
              perform="dashboard:view"
              yes={() => (
                <ListItemLink
                  to="/modulos"
                  primary="Modulos"
                  icon={<ViewModuleOutlined />}
                />
              )}
            />
            <Can
              role={user.profile}
              perform="dashboard:view"
              yes={() => (
                <ListItemLink
                  to="/protocolos" // Caminho atualizado para Protocolos
                  primary="Protocolos" // Nome atualizado
                  icon={<AssignmentOutlined />} // Ícone atualizado para algo representativo de Protocolos
                />
              )}
            />

            {showCampaigns && (
              <>
                <ListItem
                  button
                  onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                >
                  <ListItemIcon>
                    <EventAvailableIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                  />
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem onClick={() => history.push("/campaigns")} button>
                      <ListItemIcon>
                        <ConfirmationNumberOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Listagem" />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/contact-lists")}
                      button
                    >
                      <ListItemIcon>
                        <PeopleOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary="Listas de Contatos" />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/campaigns-config")}
                      button
                    >
                      <ListItemIcon>
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Configurações" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.announcements")}
                icon={<AnnouncementIcon />}
              />
            )}
            {showOpenAi && (
              <ListItemLink
                to="/prompts"
                primary={i18n.t("mainDrawer.listItems.prompts")}
                icon={<AllInclusive />}
              />
            )}

            {showIntegrations && (
              <ListItemLink
                to="/queue-integration"
                primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                icon={<DeviceHubOutlined />}
              />
            )}
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <LinkOutlinedIcon />
                </Badge>
              }
            />
            <ListItemLink
              to="/files"
              primary={i18n.t("mainDrawer.listItems.files")}
              icon={<AttachFileIcon />}
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon />}
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleOutlineIcon />}
            />
            {showExternalApi && (
              <>
                <ListItemLink
                  to="/messages-api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon={<CodeRoundedIcon />}
                />
              </>
            )}
            <ListItemLink
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<LocalAtmIcon />}
            />

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
            />

            {!collapsed && (
              <React.Fragment>
                <Divider />
                {/* 
                // IMAGE IN MENU
                <Hidden only={['sm', 'xs']}>
                  <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
                </Hidden> 
                */}
                <Typography
                  style={{
                    fontSize: "12px",
                    padding: "10px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  {`${version}`}
                </Typography>
              </React.Fragment>
            )}
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;
