import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  container: {
    display: "flex",
    justifyContent: "center", // Centraliza horizontalmente
    alignItems: "center", // Centraliza verticalmente
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    overflow: "hidden", // Garante que nada exceda a viewport
    boxSizing: "border-box",
  },
  responsiveIframe: {
    width: "100%",
    height: "100%",
    maxWidth: (props) => props.maxWidth, // Define o maxWidth dinâmico
    maxHeight: (props) => props.maxHeight, // Define o maxHeight dinâmico
    border: "none",
  },
}));

const Flow = () => {
  const [dimensions, setDimensions] = useState({ maxWidth: "100%", maxHeight: "100%" });

  const updateDimensions = () => {
    // Calcular 90% da largura e altura do monitor
    const maxWidth = window.innerWidth * 1;
    const maxHeight = window.innerHeight * .95;

    setDimensions({ maxWidth: `${maxWidth}px`, maxHeight: `${maxHeight}px` });
  };

  useEffect(() => {
    // Calcula as dimensões iniciais
    updateDimensions();

    // Atualiza as dimensões ao redimensionar a janela
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const classes = useStyles(dimensions);

  return (
  
      <iframe
        src="https://builder.rodticket.com/pt-BR/typebots"
        className={classes.responsiveIframe}
        title="Flow"
      ></iframe>
 
  );
};

export default Flow;
