import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";

// Estilos personalizados para aprimorar o design
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  dialogContent: {
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  autocomplete: {
    width: "100%",
    marginTop: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "& fieldset": {
        borderColor: theme.palette.grey[400],
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(3),
  },
  dialogActions: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  confirmButton: {
    borderRadius: "20px",
    padding: theme.spacing(1, 4),
    color: "#fff",
  },
  cancelButton: {
    borderRadius: "20px",
    padding: theme.spacing(1, 4),
    color: theme.palette.error.main,
  },
}));

const ModuloSelectDialog = ({
  openModuleSelect,
  handleCloseModuleSelect,
  handleSubmitModule,
}) => {
  const classes = useStyles();
  const [modulos, setModulos] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null); // Estado para o módulo selecionado
  const [loading, setLoading] = useState(true); // Estado para o carregamento

  useEffect(() => {
    const fetchModulos = async () => {
      try {
        const { data } = await api.get("/modulos"); // Faz a requisição à API
        setModulos(data); // Armazena os módulos no estado
        setLoading(false);
      } catch (err) {
        console.error("Erro ao buscar os módulos", err);
        setLoading(false);
      }
    };

    if (openModuleSelect) {
      setSelectedModule(null); // Resetar o módulo selecionado quando o diálogo for aberto novamente
      fetchModulos(); // Busca os módulos quando o menu é aberto
    }
  }, [openModuleSelect]);

  // Função para enviar o módulo selecionado
  const handleConfirmModule = () => {
    if (selectedModule) {
      console.log("Módulo selecionado:", selectedModule); // Confirma o módulo correto
      handleSubmitModule(selectedModule.id); // Passa o ID do módulo selecionado
      handleCloseModuleSelect(); // Fecha o diálogo
    } else {
      console.error("Nenhum módulo selecionado");
    }
  };

  return (
    <Dialog
      open={openModuleSelect}
      onClose={() => handleCloseModuleSelect(null)}
      aria-labelledby="modulo-dialog-title"
      disableEnforceFocus
    >
      <DialogTitle id="modulo-dialog-title" className={classes.dialogTitle}>
        Selecione um Módulo
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <CircularProgress className={classes.loadingIndicator} />
        ) : (
          <Autocomplete
            options={modulos}
            getOptionLabel={(modulo) => modulo.nome}
            value={selectedModule}
            onChange={(event, newValue) => setSelectedModule(newValue)}
            className={classes.autocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Buscar módulo"
                variant="outlined"
                placeholder="Digite para buscar o módulo"
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={handleCloseModuleSelect}
          className={classes.cancelButton}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirmModule}
          color="primary"
          className={classes.confirmButton}
          variant="contained"
          disabled={!selectedModule}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModuloSelectDialog;
