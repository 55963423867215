import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ContactsIcon from "@material-ui/icons/Contacts";
import ContactListDialog from "./ContactListDialog"; // Import correto do diálogo de contatos
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    color: theme.palette.primary.main,
  },
  menu: {
    minWidth: 200,
  },
  menuItemIcon: {
    color: theme.palette.text.primary,
  },
  menuItemText: {
    fontSize: 14,
  },
}));

const FileInputMenu = ({ handleFileSelect, disableOption, ticketId }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (type) => {
    handleMenuClose();

    switch (type) {
      case "document":
        document.getElementById("document-input").click();
        break;
      case "photoVideo":
        document.getElementById("photo-video-input").click();
        break;
      case "contact":
        setDialogOpen(true); // Abre o diálogo de contatos
        break;
      default:
        break;
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSelectContact = async (contact) => {
    try {
      const payload = {
        contactId: contact.id,
        name: contact.name,
        number: contact.number,
      };
  
      // Fazendo a requisição para o servidor
      const response = await api.post(`/ticket/${ticketId}/sendContact`, payload);
  
      if (response.status === 200 && response.data.success) {
        // Exibe mensagem de sucesso e registra o resultado
        console.log("Contato enviado com sucesso:", response.data);
        toast.success("Contato enviado com sucesso!");
      } else {
        // Trata falhas de resposta
        console.error("Erro ao enviar contato:", response.data.message || response.statusText);
        toast.error(response.data.message || "Erro ao enviar contato. Tente novamente.");
      }
    } catch (error) {
      // Trata erros de requisição ou conexão
      console.error("Erro ao enviar contato:", error);
      toast.error(error.response?.data?.message || "Erro inesperado ao enviar o contato.");
    }
  };
  
  

  return (
    <>
      {/* Botão principal com o ícone + */}
      <Tooltip title="Mais opções" arrow>
        <IconButton
          aria-label="more-options"
          onClick={handleMenuOpen}
          disabled={disableOption()}
        >
          <AddIcon className={classes.menuIcon} />
        </IconButton>
      </Tooltip>

      {/* Menu de opções */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menu}
      >
        <MenuItem onClick={() => handleOptionClick("document")}>
          <ListItemIcon className={classes.menuItemIcon}>
            <AttachFileIcon />
          </ListItemIcon>
          <ListItemText primary="Documento" classes={{ primary: classes.menuItemText }} />
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("photoVideo")}>
          <ListItemIcon className={classes.menuItemIcon}>
            <InsertPhotoIcon />
          </ListItemIcon>
          <ListItemText primary="Fotos e vídeos" classes={{ primary: classes.menuItemText }} />
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick("contact")}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary="Contato" classes={{ primary: classes.menuItemText }} />
        </MenuItem>
      </Menu>

      {/* Inputs invisíveis para uploads */}
      <input
        type="file"
        id="document-input"
        multiple={true}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
      <input
        type="file"
        id="photo-video-input"
        accept="image/*,video/*"
        multiple={true}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />

      {/* Diálogo de contatos */}
      <ContactListDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSelectContact={handleSelectContact}
      />
    </>
  );
};

export default FileInputMenu;
